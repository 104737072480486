import React from "react";
import { connect } from "react-redux";

import WaveLink from "components/Layout/WaveLink";

import CONXTDLogo from "components/Layout/CONXTDLogo";
import NavLink from "components/Layout/Nav/NavLink";
// import NavTitle from "./NavTitle";

import Loading from "components/Loading";

import { getEnterpriseFromRoute } from "services/redux/selectors";
import { getEnterprises } from "services/redux/selectors/enterprises";
import { loadEnterprisesStart } from "services/redux/actions";

import { withT } from "services/translation/";

import "./NavSidebar.scss";

import CurrentEnterpriseMenu from "./CurrentEnterpriseMenu";

import Icon from "components/Icons/Icon";

class Sidebar extends React.Component {
  render() {
    const { pathname, enterprise, t, user } = this.props;

    let enterprises = (
      <div className="text-center">
        <Loading />
      </div>
    );
    if (this.props.enterprises.length > 0) {
      enterprises = this.props.enterprises.map(enterprise => {
        const to = `/enterprise/${enterprise.id}`;
        return (
          <WaveLink
            key={enterprise.id}
            // className={.indexOf(to) === 0 ? "active" : ""}
            to={to}>
            <span>{enterprise.name}</span>
          </WaveLink>
        );
      });
      enterprises.unshift(
        <WaveLink to="/enterprise" key="-1">
          <span>{t("all")}</span>
        </WaveLink>
      );
    }
    return (
      <>
        <div className="left side-menu">
          <button
            type="button"
            className="button-menu-mobile button-menu-mobile-topbar open-left waves-effect"
            onClick={this.props.toggleNavCollapse}>
            <Icon className="fas fa-times" />
          </button>

          <div className="left-side-logo d-block d-lg-none">
            <div className="text-center">
              {this.props.darkMode ? <CONXTDLogo /> : <CONXTDLogo dark />}
            </div>
          </div>

          <div
            className="sidebar-inner pt-3"
            style={{
              overflowY: "scroll",
              width: "auto",
              height: "100%",
              transform: "translateZ(0)"
            }}>
            <div id="sidebar-menu">
              {/* <NavTitle>
                  <T>Main</T>
                </NavTitle> */}

              <ul
                className={`current-enterprise-menu animate-expand ${
                  enterprise ? "expanded" : ""
                }`}>
                <CurrentEnterpriseMenu pathname={pathname} />
                <div className="dropdown-divider"></div>
              </ul>

              <ul>
                <NavLink
                  onClick={active => {
                    // Closing
                    if (!active) return;
                    // Opening
                    // #214 refresh cache on opening enterprises menu
                    window.caching.flush(`LOAD_ENTERPRISES_SUCCESS/`);
                    this.props.dispatch(loadEnterprisesStart());
                  }}
                  to="/enterprise"
                  label={t("enterprises")}
                  pathname={pathname}
                  icon="fas fa-building">
                  {enterprises}
                </NavLink>
                {user.companies && user.companies === "Y" && <NavLink
                  onClick={() => {
                    this.props.dispatch(loadEnterprisesStart());
                  }}
                  dataIntercomTarget="Service Desk"
                  to="/companies"
                  label={t("companies")}
                  pathname={pathname}
                  icon="fas fa-briefcase">
                </NavLink>}
                <NavLink
                  external={1}
                  to="https://launchpad.conxtd.com"
                  label={t("app_store")}
                  pathname={pathname}
                  icon="fas fa-th-large"
                />
                <NavLink
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    console.log(window.Intercom);
                    window.Intercom("show");
                  }}
                  // external={1}
                  to="#"
                  label={t("help")}
                  pathname={pathname}
                  icon="fas fa-question-circle"
                />

                <NavLink
                  to="/logout"
                  label={t("logout")}
                  pathname={pathname}
                  icon="fas fa-sign-out-alt"
                />
                {/* <NavLink
                  external={1}
                  to="https://app.conxtd.com/#/login"
                  icon="fas fa-arrow-left"
                  label={t("enterprise_dashboard")}
                  pathname={pathname}
                /> */}
              </ul>
            </div>
            <div className="clearfix" />
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state, props) => {
  return {
    user: state.user,
    enterprises: getEnterprises(state),
    darkMode: state.app.darkMode,
    enterprise: getEnterpriseFromRoute(state, props)
  };
};
export default withT(connect(mapStateToProps)(Sidebar), "nav.sidebar");
