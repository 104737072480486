import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  Form,
  InputGroup,
  Button,
  Card,
  Row,
  Col,
  Image,
} from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

import Icon from "components/Icons/Icon";
import Loading from "components/Loading";
import logo from "../Images/conxtd_app_store_logo.jpg";
import appStore from "../Images/app_store_badge.svg";
import playStore from "../Images/play_store_badge.svg";

import ChannelIcons from "constants/Notifications/ChannelIcons";

import "./SelectResource.scss";

import { loadNotificationsResourcesStart } from "services/redux/actions";

import ErrorBadge from "components/Layout/ErrorBadge";

import { devMode } from "services/redux/selectors/";
import { withT } from "services/translation/";

function validateEmail(email) {
  // eslint-disable-next-line no-control-regex
  return /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/.test(
    email
  );
}

/**
 * Resource selector. Normalises resources
 */
const getResources = state =>
  state.notifications.resources.data.map(resource => {
    const name =
      resource.channel ||
      resource.email ||
      resource.bridge_name ||
      resource.device_type + " " + resource.device_model;
    return {
      ...resource,
      name
    };
  });

function ResourceList({ resource, resources, selectResource, channel, t }) {
  const channelIconClassName = ChannelIcons[channel] || ChannelIcons.default;

  return (
    <Form>
      <Form.Group controlId="selectResourceForm.ResourceList">
        <InputGroup className="mb-3">
          <InputGroup.Prepend>
            <span className="input-group-text">
              <Icon className={channelIconClassName} />
            </span>
          </InputGroup.Prepend>
          <Typeahead
            // ref={r => {
            //   this.Typeahead = r;
            // }}
            allowNew={channel === "email"}
            clearButton
            id="typeahead-select-resource"
            placeholder={t(`select_resource_labels.${channel}`)}
            newSelectionPrefix={t("add_new_email_address")}
            inputProps={{
              id: "typeahead-input",
              autoComplete: "off",
              "data-testid": "typeahead-select-resource"
            }}
            options={resources}
            onChange={options => {
              // Reset selection
              if (options.length === 0) {
                selectResource(null);

                return;
              }
              const option = options[0];

              // String (name) will create new email, int will send id
              const resource = option.customOption ? option.name : option.id;

              // New email address - validate
              if (typeof resource === "string") {
                if (!validateEmail(resource)) {
                  selectResource(null);

                  return;
                }
              }

              selectResource(resource === "-1" ? null : resource);
            }}
            renderMenuItemChildren={(option, props, index) => {
              return (
                <div>
                  <Icon className={channelIconClassName} />
                  <span className="pl-2">{option.name}</span>
                </div>
              );
            }}
            labelKey="name"
          />
        </InputGroup>
        {channel === "email" && typeof resource === "string" && (
          <Form.Text className="text-muted">
            {t("new_email_will_be_added")}
          </Form.Text>
        )}
      </Form.Group>
    </Form>
  );
}

/**
 * Select notification resource based on channel
 */
function SelectResource({
  channel,
  resources,
  resource,
  loading,
  error = {},
  selectResource,
  loadResources,
  t
}) {
  // Get resources from API, through saga
  useEffect(() => {
    // Dispatch api call if data is in initial state
    if (loading !== true && !error.status) {
      loadResources({
        params: {
          channel
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  if (loading === true) {
    return <Loading center />;
  }

  if (error.status || error.message) {
    return (
      <ErrorBadge
        message={t(`error${devMode() ? "_dev" : ""}`, error.message)}
      />
    );
  }
  // if a user is new and has selected Slack
  if (resources.length === 0 && channel === "slack") {
    return (
      <div>
        Go to App Store to setup:
        <Button
          className="slackButton"
          size="sm"
          variant="outline-primary"
          href="https://launchpad.conxtd.com"
        >
          <Icon className="fas fa-th-large" />
        </Button>
      </div>
    );
  }
  // else if a user is new and has selected Push
  else if (resources.length === 0 && channel === "push") {
    return (
      <div>
        <Card
          className="cardContainer"
          style={{ height: "auto", width: "100%" }}
        >
          <Card.Body>
            <Row>
              <Col>
                <p>
                  Please log in using the CONXTD Enterprise App on the
                  phone/tablet that you would like to see push notifications on.
                </p>
                <p>
                  Once you have logged in on your device, it will appear here.
                </p>
              </Col>
            </Row>

            <Row>
              <Col>
                <Card.Img
                  className="rounded mb-0 border border-dark"
                  variant="top"
                  src={logo}
                  style={{ height: "146px", width: "146px" }}
                />
                <h1 className="logoText">CONXTD Enterprise</h1>
              </Col>
              <Col>
                <a href="https://apps.apple.com/gb/app/conxtd-enterprise/id1444742023">
                  <Image
                    className="appStore"
                    src={appStore}
                    style={{ height: "40px", width: "120px" }}
                  />
                </a>

                <a href="https://play.google.com/store/apps/details?id=com.ConxtdTechnologiesLtd.CONXTDv2Enterprise&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                  <Image
                    className="playStore"
                    src={playStore}
                    style={{ height: "40px", width: "120px" }}
                  />
                </a>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    );
  }

  return (
    <ResourceList
      resource={resource}
      channel={channel}
      resources={resources}
      selectResource={selectResource}
      t={t}
    />
  );
}
SelectResource.propTypes = {
  /**
   * @param channel Currently selected notification channel
   */

  channel: PropTypes.string.isRequired,
  /**
   * @param resources Resources available from backend
   */

  resources: PropTypes.array.isRequired,
  /**
   * @param resource the currently selected resource
   */

  resource: PropTypes.any,
  /**
   * @param loading Api loading status
   */

  loading: PropTypes.any,
  /**
   * @param error Api error
   */

  error: PropTypes.object,
  /**
   * @param selectResource Resource select callback function
   */

  selectResource: PropTypes.func.isRequired,
  /**
   * @param loadResources Redux dispatch function for sending api request
   */

  loadResources: PropTypes.func.isRequired,
  /**
   * @param t Translation function
   */

  t: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  loadResources: loadNotificationsResourcesStart
};
const mapStateToProps = state => {
  return {
    resources: getResources(state),
    loading: state.notifications.resources.loading,
    error: state.notifications.resources.error
  };
};

export default withT(
  connect(mapStateToProps, mapDispatchToProps)(SelectResource),
  "site_overview.side_pane.manage_notifications.add_rule.select_resource"
);
