import { createSelector } from "reselect";

import _ from "lodash";

import { createGetEventMetaById } from "services/redux/selectors/eventMeta";

export const getIntegrations = (state) => state.enterpriseManager.integrations;
export const getBrivoSites = (state) => state.enterpriseManager.brivo.sites;
export const getBrivoLinkedSites = (state) => state.enterpriseManager.brivo.linkedSites;

export const getEventPairList = (state) => state.enterpriseManager.priorityEventsManagement.eventPairList;
export const getPinMapEventPairList = (state) => state.eventPairs.data;
export const getStepTypeList = (state) => state.enterpriseManager.priorityEventsManagement.stepTypeList;

export const getEnabledIntegrations = createSelector(
  [getIntegrations],
  (integrations) => {

    const enabledIntegrations = [];

    if (integrations) {
      integrations.forEach(integration => {
        if (integration.active) {
          enabledIntegrations.push(integration);
        }
      });
    }

    return enabledIntegrations;
  }
);

export const getAvailableIntegrations = createSelector(
  [getIntegrations],
  (integrations) => {

    const availableIntegrations = [];

    if (integrations) {
      integrations.forEach(integration => {
        if (!integration.active) {
          availableIntegrations.push(integration);
        }
      });
    }

    return availableIntegrations;
  }
);

export const getBrivoSitesWithLinks = createSelector(
  [getBrivoSites, getBrivoLinkedSites],
  (brivoSites, brivoLinkedSites) => {

    let brivoSitesWithLinks = [];

    if (brivoSites &&brivoSites.length > 0) {
      if (brivoLinkedSites && brivoLinkedSites.length > 0) {
        brivoSites.forEach(brivoSite => {
          const linkedSite = _.find(brivoLinkedSites, ["brivo_site_id", String(brivoSite.id)]);
          if (linkedSite) {
            brivoSitesWithLinks.push({
              ...brivoSite,
              ...linkedSite,
            });
          } else {
            brivoSitesWithLinks.push({
              ...brivoSite,
            });
          }
        });
      } else {
        brivoSitesWithLinks = [ ...brivoSites ];
      }
    }

    return brivoSitesWithLinks;
  }
);

export const getEventPairOptions = createSelector(
  [getEventPairList],
  (eventPairList) => {
    return eventPairList
      ? eventPairList.map((eventPair) => {
          return (
            {
              label: eventPair.name,
              value: eventPair.id
            }
          )
        })
      : []
  }
);

export const getStepTypeOptions = createSelector(
  [getStepTypeList],
  (stepTypeList) => {
    return stepTypeList
      ? stepTypeList.map((stepType) => {
          return (
            {
              label: stepType.step,
              value: stepType.id
            }
          )
        })
      : []
  }
);

// retrieves EventPair names and IDs only
export const getPinMapEventPairOptions = createSelector(
  [getPinMapEventPairList],
  (pinMapEventPairList) => {
    return pinMapEventPairList
      ? pinMapEventPairList.map((pinMapEventPair) => {
          return (
            {
              label: pinMapEventPair.name,
              value: pinMapEventPair.event_pair_id
            }
          )
        })
      : []
  }
);

export const getEventPairMaps = createSelector(
  [
    (state) => state.enterpriseManager.priorityEventsManagement.eventPairMaps,
    createGetEventMetaById
  ],
  (eventPairMaps, getEventMetaById) => {
    return {
      ...eventPairMaps,
      default_event_pair_maps: [
        ...eventPairMaps.default_event_pair_maps.map((eventPairMap) => {
          return {
            ...eventPairMap,
            eventMeta: getEventMetaById(eventPairMap.conxtd_alarm_id)
          }
        })
      ],
      enterprise_event_pair_maps: [
        ...eventPairMaps.enterprise_event_pair_maps.map((eventPairMap) => {
          return {
            ...eventPairMap,
            eventMeta: getEventMetaById(eventPairMap.conxtd_alarm_id)
          }
        })
      ],
    };
  }
)