import { combineReducers } from "redux";
import { responsiveStateReducer } from "redux-responsive";
import { reducer as modalProviderReducer } from "react-redux-modal-provider";

//import postsReducer from "./postsReducer";
import errorsReducer from "./errorsReducer";
import userReducer from "./user/";
import usersReducer from "./users/";
import developersReducer from "./developersReducer";
import appReducer from "./appReducer";
import cacheIndexesReducer from "./cacheIndexesReducer";
import enterprisesReducer from "./enterprisesReducer";
import reportsReducers from "./reports/";
import groupsReducer from "./groupsReducer";
import sitesReducer from "./sites/index";
import eventMetaReducer from "./eventMetaReducer";
import eventPairsReducer from "./eventPairsReducer";
import userActivityReducer from "./userActivityReducer";
import utilityReducer from "./utility/";
import notificationsReducer from "./notifications/";
// import dashboardsReducer from "./dashboards/";
import commentsReducer from "./commentsReducer";
import enterpriseManagerReducer from "./enterpriseManagerReducer";
import enterpriseManagerPersistReducer from "./enterpriseManagerPersistReducer";
import alarmResponseReducer from "./alarmResponseReducer"; //alarm-response
import voiceCallsReducer from "./voiceCallsReducer"; 
import customReportsReducer from "./customReportsReducer";
import companiesReducer from "./companiesReducer";
import companyReducer from "./companyReducer";
import sopReducer from "./sopReducer";
import sopReportsReducer from "./sopReportsReducer";
import { reducer as formReducer } from "redux-form";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  // whitelist: ["user", "groups", "enterpriseManager"],
  whitelist: ["user", "groups", "enterpriseManagerPersist"],
};

const rootReducer = combineReducers({
  user: userReducer,
  users: usersReducer,
  developers: developersReducer,
  form: formReducer,
  error: errorsReducer,
  app: appReducer,
  cacheIndexes: cacheIndexesReducer,
  enterprises: enterprisesReducer,
  reports: reportsReducers,
  groups: groupsReducer,
  sites: sitesReducer,
  eventMeta: eventMetaReducer,
  eventPairs: eventPairsReducer,
  userActivity: userActivityReducer,
  utility: utilityReducer,
  notifications: notificationsReducer,
  // dashboards: dashboardsReducer
  comments: commentsReducer,
  browser: responsiveStateReducer,
  enterpriseManager: enterpriseManagerReducer,
  enterpriseManagerPersist: enterpriseManagerPersistReducer,
  alarmResponse: alarmResponseReducer,
  modalProvider: modalProviderReducer,
  voiceCalls: voiceCallsReducer,
  customReports: customReportsReducer,
  companies: companiesReducer,
  company: companyReducer,
  sop: sopReducer,
  sopReports: sopReportsReducer,
});

export default persistReducer(persistConfig, rootReducer);
