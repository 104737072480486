/**
 * Notification rules reducer
 */

import add from "./add";
import remove from "./remove";
import load from "./load";

export default function(state = {}, action) {
  switch (action.type) {
    case "REQUEST_NOTIFICATIONS_ADD_RULE_START":
    case "REQUEST_NOTIFICATIONS_ADD_RULE_FAILED":
    case "REQUEST_NOTIFICATIONS_ADD_RULE_SUCCESS":
    case "REQUEST_NOTIFICATIONS_ADD_RULE_NOTIFIED":
      return {
        ...state,
        actions: {
          ...state.actions,
          add: add(state.actions.add, action)
        }
      };
    case "REQUEST_NOTIFICATIONS_REMOVE_RULE_START":
    case "REQUEST_NOTIFICATIONS_REMOVE_RULE_FAILED":
    case "REQUEST_NOTIFICATIONS_REMOVE_RULE_NOTIFIED":
      return {
        ...state,
        actions: {
          ...state.actions,
          remove: remove(state.actions.remove, action)
        }
      };
    case "REQUEST_NOTIFICATIONS_REMOVE_RULE_SUCCESS":
      return {
        ...state,
        // API returned successfully, remove it from our
        // local state
        data: state.data.filter(
          rules => rules.id !== action.startAction.params.id
        ),
        actions: {
          ...state.actions,
          remove: remove(state.actions.remove, action)
        }
      };
    case "LOAD_NOTIFICATIONS_RULES_START":
    case "LOAD_NOTIFICATIONS_RULES_FAILED":
    case "LOAD_NOTIFICATIONS_RULES_SUCCESS":
      return load(state, action);

    default:
      return state;
  }
}
