import React from "react";
import { connect } from "react-redux";

import ReactTable from "react-table";
import "react-table/react-table.css";

import { ButtonToolbar } from "react-bootstrap";

import { changeGroupTimeframe } from "services/redux/actions/";
import { clearCustomReport } from "services/redux/actions/customReports";

import { getCustomReportData } from "services/redux/selectors";
import { getCustomRef } from "services/redux/selectors/groups";
import { getActualWithTimezone } from "services/redux/selectors/customReports";
import { sortNumber } from "services/redux/selectors/reports/scheduled";

import SiteNameCell from "components/Common/DataTable/SiteNameCell/";
import EventCell from "components/Common/DataTable/EventCell/";
import ExportDropdown from "components/Enterprise/Export/ExportDropdown";
import SiteView from "components/Site/SiteView/";

import ActualTimezoneDate from "components/Common/ActualTimezoneDate";

import moment from "services/locale/momentInit.js";

import _ from "lodash";

class DataTable extends React.Component {
  state = {
    showSidePane: false,
    selectedSite: null,
    bundle_id: null,
  };

  onSelectSite = (site, row) => {
    const { selectedSite } = this.state;

    // New site selected
    if (site && site !== selectedSite) {
      document.body.classList.add("sidepane-active");

      const acked = new moment(row.acked);

      // Timeframe is from 6 hours before the time of the event until now
      let timeframe = new moment().diff(acked, "hours") + 6;

      // Limit timeframe to maximum of 4 weeks
      if (timeframe > 672) timeframe = 672;

      this.props.dispatch(
        changeGroupTimeframe({
          timeframe
        })
      );

      this.setState({
        showSidePane: true,
        selectedSite: {
          ...site,
          mct_alarm_log_id: row.id,
        },
        bundle_id: 2,
      });
    }
    // Clicked a site that's already being viewed, close
    else {
      document.body.classList.remove("sidepane-active");

      // Delay by 600ms to make sidepane exit gracefully
      setTimeout(() => {
        this.setState({
          showSidePane: false,
          selectedSite: null,
          bundle_id: null,
        });
      }, 600);
    }
  };

  componentWillUnmount() {
    this.props.dispatch(
      clearCustomReport({})
    );
  }

  render() {

    const { customReport, loadingCustomReport } = this.props;

    const { selectedSite } = this.state;

    let noDataMsg = "No data available...";

    const columns = [
      {
        key: "CUSTOM_REF",
        Header: "Ref",
        id: "custom_ref",
        className: "text-center",
        width: 60,
        accessor: (data) => {
          if (!_.isEmpty(data.site)) {
            return getCustomRef(data.site);
          } 
        },
      },
      {
        key: "SITE_NAME",
        Header: "Site Name",
        id: "site_name",
        className: "site-name",
        accessor: data => data.site.name,
        Cell: (props) => {
          if (!_.isEmpty(props.original.site)) { 
            return (
              <SiteNameCell
                // {...row}
                value={props.original.site.name}
                selectSite={() => {
                  this.onSelectSite(
                    props.original.site,
                    props.original,
                  );
                }}
              />
            )
          } 
        }
      },
      {
        key: "SITE_REF",
        Header: "Site Ref",
        width: 80,
        accessor: "site_ref",
      },
      // {
      //   key: "CONXTD_ALARM_ID",
      //   Header: "Alarm Id",
      //   width: 80,
      //   accessor: "conxtd_alarm_id",
      // },
      {
        key: "EVENT",
        Header: "Event",
        id: "event",
        className: "alarm-type",
        width: 190,
        accessor: data => data.event_meta.caption,
        Cell: ({ original }) => {
          return <EventCell
            event={original.event_meta}
          />
        }
      },
      // {
      //   key: "ALARM",
      //   Header: "Alarm",
      //   width: 160,
      //   accessor: "alarm",
      // },
      {
        key: "TIME",
        Header: "Time",
        id: "occurrence",
        width: 160,
        accessor: data => {
          const occurrence = {
            acked: data.acked,
            timezone: data.site.timezone,
            format: "HH:mm ddd DD/MM/YYYY",
          }
          return occurrence;
        },
        Cell: ({ row }) => {
          return <ActualTimezoneDate occurrence={row.occurrence} /> //note: this is a slightly different <ActualTimezoneDate component to the one used in the scheduled reports
        },
        sortMethod: (occurrenceA, occurrenceB) => {
          let ackedA;
          let ackedB;
          
          if (occurrenceA.timezone) {
            const localDate = new moment(occurrenceA.acked);
            ackedA = new moment.utc(localDate).tz(occurrenceA.timezone);
          } else {
            ackedA = occurrenceA.acked;
          }

          if (occurrenceB.timezone) {
            const localDate = new moment(occurrenceB.acked);
            ackedB = new moment.utc(localDate).tz(occurrenceB.timezone);
          } else {
            ackedB = occurrenceB.acked;
          }

          const a = moment(ackedA).format("YYYYMMDDHHmmss");
          const b = moment(ackedB).format("YYYYMMDDHHmmss");
          return sortNumber(a, b);
        },
        filterMethod: (filter, row) => {
          return (
            String(getActualWithTimezone(row.occurrence)) //note: this is a slightly different getActualWithTimezone function to the one used in the scheduled reports
              .toLowerCase()
              .indexOf(filter.value.toLowerCase()) !== -1
          );
        },
      },
      {
        key: "TEXT",
        Header: "Text",
        minWidth: 200,
        accessor: "text",
      },
      {
        key: "AREA",
        Header: "Area",
        width: 80,
        accessor: "area",
      },
      {
        key: "ZONE",
        Header: "Zone",
        width: 80,
        accessor: "zone",
      },
    ]

    return (
      <div>
        {/* <div className="card visual-data site-list"> */}
        <div className="card visual-data">
          <ButtonToolbar className="p-2">
            <ExportDropdown
              disabled={loadingCustomReport || (customReport.length === 0)}
              reportTitle={"Custom Event Report"}
              dataResolver={(fileFormat) => {
                const data = this.reactTable.getResolvedState().sortedData;
    
                let result = data.map(row => {
                  // console.log(row);
                  const occurrence = {
                    acked: row._original.acked,
                    timezone: row._original.site.timezone,
                    format: "HH:mm ddd DD/MM/YYYY",
                  }

                  return [
                    row["custom_ref"] || "",
                    row["site_name"] || "",
                    row["site_ref"] || "",
                    // row["event"] || "",
                    fileFormat === "EXCEL"
                      ? row._original.event_meta
                          ? {
                              // v: (row._original.event_meta && (row._original.event_meta.emoji ? row._original.event_meta.emoji + " " : "")) + row["event"] || "",
                              v: row["event"] || "",
                              t: "s",
                              s: {
                                fill: {
                                  fgColor: {
                                    rgb: (row._original.event_meta.colour[0] === "#")
                                            ? row._original.event_meta.colour.substring(1)
                                            : row._original.event_meta.colour
                                  }
                                },
                                font: {
                                  color: {
                                    rgb: "FFFFFF"
                                  }
                                }
                              }
                            }
                          : row["event"] || ""
                      : row["event"] || "",
                    getActualWithTimezone(occurrence), //note: this is a slightly different getActualWithTimezone function to the one used in the scheduled reports
                    row["text"] || "",
                    row["area"] || "",
                    row["zone"] || ""
                  ];
                });
                result.unshift([
                  "Custom Ref",
                  "Site Name",
                  "Site Ref",
                  "Event",
                  "Time",
                  "Text",
                  "Area",
                  "Zone"
                ]);
    
                return result;
              }}
    
            />
          </ButtonToolbar>
          <ReactTable
            ref={r => (this.reactTable = r)}
            columns={columns}
            // data={tableData}
            data={loadingCustomReport ? [] : customReport}
            // className={`${expandedRowsCount > 0 ? "focus-expanded" : ""}`}
            filterable
            defaultFilterMethod={(filter, row) => {
              return (
                String(row[filter.id])
                  .toLowerCase()
                  .indexOf(filter.value.toLowerCase()) !== -1
              );
            }}
            // {...checkboxProps}
            defaultPageSize={20}
            noDataText={noDataMsg}
            defaultSorted={[
              {
                id: "occurrence",
                desc: true,
              },
            ]}
            // TrGroupComponent={TrGroupComponent} 
            // getTrGroupProps={(state, rowInfo, column) => {
            //   if (!rowInfo) {
            //     return { 
            //       columns,
            //       expanded: 0,
            //       className: "empty-row",
            //     }
            //   } else {
            //     // Sets background to alarm id colour
            //     const colorKey = rowInfo.original.sudo_state[0].state === 'R' ? "restoreGreen" : rowInfo.original.sudo_state[0].state === 'A' ? "failRed" :  "testGrey";
            //     // const color = COLORS[colorKey];
            //     return {
            //       columns,
            //       expanded: getExpanded(rowInfo.original) ? 1 : 0,
            //       inAlarmSite: rowInfo.original,
            //       className: [colorKey, true, "waves-effect"].join(" "),
            //       // style: {
            //       //   background: color,
            //       //   border: "1px solid " + color,
            //       //   // border: "3px solid transparent",
            //       // },
            //       id: "id"+rowInfo.original.id,
            //       getEventMetaById,
            //     };
            //   }
            // }}
          />
          <SiteView
            site={selectedSite}
            selectSite={this.onSelectSite}
            bundle_id={this.state.bundle_id}
            onSelectBundle={bundle_id => {
              // Bundle selected
              if (bundle_id) {
                this.setState({ bundle_id });
              }
              // Invalid bundle id, deselect
              else {
                this.setState({ bundle_id: null });
              }
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    customReport: getCustomReportData(state, props),
    loadingCustomReport: state.customReports.loadingCustomReport,
  };
};
export default connect(mapStateToProps)(DataTable);