/**
 * 09/21 - open close by area
 * autocomplete event filter for area state field in data table
 */

import React from "react";

import FilterSelect from "components/Common/Dropdown/FilterSelect";

export default ({ filter, onChange, column }) => {
  const { getEventMetaById, areaFilterIds } = column;

  const options = areaFilterIds.map((id) => {
    const eventMeta = getEventMetaById(id);
    return {
      name: eventMeta.caption,
      color: eventMeta.colour,
      id: eventMeta.id,
      icon: eventMeta.icon,
    }
  });



  // const closeEvent = getEventMetaById(396);
  // const openEvent = getEventMetaById(395);

  // options.push({
  //   name: closeEvent.caption,
  //   color: closeEvent.colour,
  //   id: closeEvent.id,
  //   icon: closeEvent.icon,
  // });

  // options.push({
  //   name: openEvent.caption,
  //   color: openEvent.colour,
  //   id: openEvent.id,
  //   icon: openEvent.icon,
  // });

  return (
    <FilterSelect
      onChange={onChange}
      value={filter ? filter.value : ""}
      options={options}
    />
  );
};