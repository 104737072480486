import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { withT } from "services/translation/";

import get from "lodash/get";

// import Alert from "components/Common/Alert";

import moment from "services/locale/momentInit.js";

import { createGetEventMetaById } from "services/redux/selectors/eventMeta";
import { getCurrentSite } from "services/redux/selectors/sites";

// import CircleIcon from "components/Icons/CircleIcon";
import SVGIcon from "components/Icons/SVGIcon";
import Icon from "components/Icons/Icon";

import { 
  addLinkedComment,
  changeGroupTimeframe,
  loadNotificationHistoryStart,
} from "services/redux/actions/";

import TimezoneDate from "components/Common/TimezoneDate";
import TimeagoTimezone from "components/Common/TimeagoTimezone";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

import "./Timeline.scss";

import LoadMore from "components/Common/LoadMore/";

import Loading from "components/Loading";

import ClipList from "./ClipList/";
// import CameraList from "components/Common/Cameras/CameraList/CameraList";

import Alert from "components/Common/Alert";

import { Tooltip } from "components/Common/Tooltip/";

import { Form, Button, Row } from "react-bootstrap";

import conxtdOut from "apis/conxtdOut";

class Timeline extends React.Component {
  state = {
    eventExpanded: {},
    contentTypes: {},
  };

  scrollToSelectedEvent = () => {
    const { site } = this.props;

    const mct_alarm_log_id =
      site.mct_alarm_log_id || get(site, "occurrences.0.mct_alarm_log_id");

    // Scroll to selected event if mct_alarm_log_id is present on first of site occurrence
    if (mct_alarm_log_id) {
      const selectedEventElement = document.querySelector(
        `#mct-alarm-log-id-${mct_alarm_log_id}`
      );

      if (selectedEventElement) {
        selectedEventElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  toggleExpandEvent = (eventElementId, contentType, mctAlarmLogId, notificationCount) => {
    const { site, notificationHistories } = this.props;
    const { eventExpanded, contentTypes } = this.state;

    const currentExpanded = typeof eventExpanded[eventElementId] === "undefined" 
                            ? false 
                            : eventExpanded[eventElementId];

    let newExpanded = !currentExpanded; // default is to toggle between expanded/not expanded

    if (contentType) {
      if (contentType === "notifications" && notificationCount > 0) {
        // if we don't already have the notifications for this event loaded, load them now
        if (!notificationHistories[mctAlarmLogId]) {
          this.props.dispatch(
            loadNotificationHistoryStart({
              enterprise_site_id: site.id,
              mct_alarm_log_id: mctAlarmLogId, 
            })
          )
        }
      }
      if (currentExpanded) { // it was already expanded
        const currentContentType = typeof contentTypes[eventElementId] === "undefined" 
                                    ? null 
                                    : contentTypes[eventElementId];
        if (currentContentType !== contentType) {
          newExpanded = true; // keep it expanded if we're switching between content types
        }
      }
      this.setState({
        contentTypes: { ...contentTypes, [eventElementId]: contentType }
      })
    }

    this.setState({
      eventExpanded: { ...eventExpanded, [eventElementId]: newExpanded }
    })
  }

  getUserIcon = (firstName, lastName) => {
    return (
      <div className="link-comment-icon">
        <Tooltip
          description={`${firstName} ${lastName}`}
          placement="left"
        >
          <span>{`${firstName[0]}${lastName[0]}`}</span>
        </Tooltip>
      </div>
    )
  }

  handleSubmit = (mctAlarmLogId, comment) => {
    const { user, site, bundle } = this.props;

    const text = encodeURIComponent(comment);
    const time = moment.utc().format();

    const textAreaElement = document.getElementById(`textarea-${mctAlarmLogId}`);
    const submitButtonElement = document.getElementById(`submit-${mctAlarmLogId}`);

    const postComment = conxtdOut.post(`/LinkedComments/add/${site.id}`, {
      mct_alarm_log_id: mctAlarmLogId,
      type: "comments",
      text: text,
      time: time,
      first_name: user.firstName,
      last_name: user.lastName,
    });

    textAreaElement.classList.add("cursor-progress");
    submitButtonElement.classList.add("cursor-progress");
    textAreaElement.disabled = true;
    submitButtonElement.disabled = true;

    postComment.then((response) => {
      if (response.data && response.data.message === "Success") {
        this.props.dispatch(
          addLinkedComment({
            bundle_id: bundle.id,
            mct_alarm_log_id: mctAlarmLogId,
            text: text,
            time: time,
            first_name: user.firstName,
            last_name: user.lastName,
          })
        );
        textAreaElement.value = "";
      }
      textAreaElement.classList.remove("cursor-progress");
      submitButtonElement.classList.remove("cursor-progress");
      textAreaElement.disabled = false;
      submitButtonElement.disabled = false;
    }).catch((error) => {
      console.log("Error details", error);
      Alert({
        text: "Issue posting comment. Please try again later.",
        icon: "warning",
        showConfirmButton: true,
      });
      textAreaElement.classList.remove("cursor-progress");
      submitButtonElement.classList.remove("cursor-progress");
      textAreaElement.disabled = false;
      submitButtonElement.disabled = false;
    })
  }

  componentDidMount() {
    this.scrollToSelectedEvent();
  }

  componentDidUpdate(prevProps) {
    if (this.props.site.highlightEvent && prevProps && (prevProps.site.id !== this.props.site.id)) {
      this.scrollToSelectedEvent();
    }
  }

  render() {
    const { user, getEventMetaById, bundle, site, notificationHistories, dispatch, t } = this.props;
    const { eventExpanded, contentTypes } = this.state;

    const mct_alarm_log_id =
      site.mct_alarm_log_id || get(site, "occurrences.0.mct_alarm_log_id");

    if (!bundle) return null;

    return (
      <div className="row">
        <div className="col-12">
          <VerticalTimeline layout="1-column" className="py-0">
            {bundle.events.map(
              (
                {
                  id: bundle_mct_alarm_log_id,
                  acked,
                  text,
                  camera_events,
                  conxtd_alarm_id,
                  event_field,
                  linked_comments,
                  notification_count,
                },
                index
              ) => {
                const event = getEventMetaById(conxtd_alarm_id) || {
                  colour: "#707070",
                  icon: "default",
                  caption: "Unknown",
                  description: "",
                };

                const eventMoment = new moment(acked);
                const yesterday = new moment()
                  .startOf("day")
                  .isAfter(eventMoment);

                const beforeYesterday = new moment()
                  .subtract(1, "days")
                  .startOf("day")
                  .isAfter(eventMoment);

                const format = beforeYesterday
                  ? "dddd MMMM Do, YYYY HH:mm"
                  : `[${yesterday ? t("yesterday_at") : t("today_at")}] HH:mm`;

                // Highlight this event as it is the focused event for this
                // context
                const highlighted =
                  site.highlightEvent && bundle_mct_alarm_log_id === mct_alarm_log_id;

                const expanded = typeof eventExpanded[`mct-alarm-log-id-${bundle_mct_alarm_log_id}`] === "undefined" 
                                  ? false 
                                  : eventExpanded[`mct-alarm-log-id-${bundle_mct_alarm_log_id}`]

                const contentType = typeof contentTypes[`mct-alarm-log-id-${bundle_mct_alarm_log_id}`] === "undefined" 
                                    ? "comments" 
                                    : contentTypes[`mct-alarm-log-id-${bundle_mct_alarm_log_id}`]

                const notificationHistory = notificationHistories[bundle_mct_alarm_log_id] ? notificationHistories[bundle_mct_alarm_log_id] : null;

                return (
                  <VerticalTimelineElement
                    key={index}
                    id={`mct-alarm-log-id-${bundle_mct_alarm_log_id}`}
                    className={`vertical-timeline-element--work text-white-important py-4 my-2 ${
                      highlighted ? "highlighted-event" : ""
                    } ${
                      expanded? "timeline-event-expanded" : ""
                    }`}
                    contentStyle={{
                      borderTop: "3px solid " + event.colour,
                      background: event.colour,
                    }}
                    contentArrowStyle={{
                      borderRight: "7px solid " + event.colour,
                    }}
                    // "Today at 8:24 AM"
                    // date={
                    //   <TimezoneDate
                    //     date={eventMoment}
                    //     timezone={site.timezone}
                    //     localFormat={format}
                    //     placement="bottom"
                    //   />
                    // }
                    iconStyle={{ background: event.colour, marginTop: "2em" }}
                    icon={<SVGIcon color={"#ffffff"} type={event.icon} />}
                  >
                    <div className="row">
                      <div className="col-7">
                        <div className="vertical-timeline-element-title text-uppercase font-weight-bold font-16">
                          {event.caption}
                        </div>
                        <div className="vertical-timeline-element-subtitle font-14 mt-2">
                          {event.description}
                        </div>
                      </div>
                      {/* Event field - zone/area/loop/panel/point */}
                      <div className="col-5">
                        {event_field && (event_field.area || event_field.zone) && (
                          <div className="row">
                            {event_field.area && (
                              <div className="col-12 text-right">
                                {t("area_x", event_field.area)}
                              </div>
                            )}
                            {event_field.zone && (
                              <div className="col-12 text-right">
                                {t("zone_x", event_field.zone)}
                              </div>
                            )}
                            {event_field.loop && (
                              <div className="col-12 text-right">
                                {t("loop_x", event_field.loop)}
                              </div>
                            )}
                            {event_field.panel && (
                              <div className="col-12 text-right">
                                {t("panel_x", event_field.panel)}
                              </div>
                            )}
                            {event_field.point && (
                              <div className="col-12 text-right">
                                {t("point_x", event_field.point)}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mt-2" style={{whiteSpace: "pre-wrap"}}>{decodeURIComponent(text)}</div>
                    {camera_events.length > 0 && (
                    <>
                      <ClipList camera_events={camera_events} index={index} time={acked} colour={event.colour} conxtd_alarm_id={conxtd_alarm_id}/>
                    </>
                    )}
                    <div className="timeline-footer">
                      <div className="timeline-date-footer">
                        <TimezoneDate
                          date={eventMoment}
                          timezone={site.timezone}
                          localFormat={format}
                          placement="bottom"
                        />
                      </div>
                      <div className="d-flex">
                        <Tooltip
                          description="View notifications"
                          placement="top"
                        >
                          <div className="timeline-count-container pr-1 pl-1">
                            <div className="timeline-count-icon">
                              <SVGIcon color={notification_count === 0 ? "#bbb" : "#ffffff"} type={"bell"} />
                            </div>
                            <div 
                              className="timeline-count-number text-body"
                              onClick={() => {
                                this.toggleExpandEvent(`mct-alarm-log-id-${bundle_mct_alarm_log_id}`, "notifications", bundle_mct_alarm_log_id, notification_count)
                              }}
                            >
                              {notification_count}
                            </div>
                          </div>
                        </Tooltip>
                        <Tooltip
                          description={linked_comments.length === 0 ? "Start conversation" : "View conversation"}
                          placement="top"
                        >
                          <div className="timeline-count-container pr-1 pl-1">
                            <div className="timeline-count-icon">
                              <SVGIcon color={linked_comments.length === 0 ? "#bbb" : "#ffffff"} type={"comment"} />
                            </div>
                            {
                              linked_comments.length === 0 
                              ? 
                                <div 
                                  className="timeline-count-number timeline-comments-plus text-body"
                                  onClick={() => {
                                    this.toggleExpandEvent(`mct-alarm-log-id-${bundle_mct_alarm_log_id}`, "comments")
                                  }}
                                >
                                  +
                                </div>
                              : 
                                <div 
                                  className="timeline-count-number text-body"
                                  onClick={() => {
                                    this.toggleExpandEvent(`mct-alarm-log-id-${bundle_mct_alarm_log_id}`, "comments")
                                  }}
                                >
                                  {linked_comments.length}
                                </div>
                            }
                          </div>
                        </Tooltip>
                        <Tooltip
                          description={expanded ? "Hide details" : "Show details"}
                          placement="top"
                        >
                          <div 
                            className="timeline-event-expand-chevron"
                            onClick={() => {
                              this.toggleExpandEvent(`mct-alarm-log-id-${bundle_mct_alarm_log_id}`, null)
                            }}
                          >
                            <Icon 
                              className={expanded ? "fas fa-chevron-up" : "fas fa-chevron-down" }
                            />
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="mt-2 timeline-expand-container">
                      {contentType === "comments" ?
                        <div>
                          <VerticalTimeline 
                            layout="1-column" 
                            className={`link-comments-timeline ${linked_comments.length === 0 ? "link-comments-empty" : ""}`}
                          >
                            {linked_comments.map((comment, index) => {
                              return (
                                <VerticalTimelineElement
                                  key={index}
                                  className="text-body link-comment-container"
                                  icon={this.getUserIcon(comment.user.first_name, comment.user.last_name)}
                                  iconStyle={{ background: "#2c3e50" }}
                                  date={
                                    <TimeagoTimezone
                                      date={new moment(comment.time)}
                                      timezone={site.timezone}
                                      localFormat={format}
                                      placement="bottom"
                                    />
                                  }
                                >
                                  <div className="link-comment-header text-muted">{`${comment.user.first_name} ${comment.user.last_name}`}</div>
                                  <div className="link-comment text-dark">{decodeURIComponent(comment.text)}</div>
                                </VerticalTimelineElement>
                              )
                            })}
                          </VerticalTimeline>
                          <VerticalTimeline 
                            layout="1-column" 
                            className="link-comments-reply"
                          >
                            <VerticalTimelineElement
                              key={0}
                              className="text-body link-comment-container"
                              icon={this.getUserIcon(user.firstName, user.lastName)}
                              iconStyle={{ background: "#2c3e50" }}
                            >
                              <Form 
                                id = {`form-${bundle_mct_alarm_log_id}`} 
                                onSubmit={(event) => {
                                  event.preventDefault();
                                  this.handleSubmit(bundle_mct_alarm_log_id, event.target.comment.value)
                                }}
                              >
                                <Form.Control
                                  id = {`textarea-${bundle_mct_alarm_log_id}`} 
                                  as="textarea"
                                  rows={5}
                                  name="comment"
                                  required
                                />
                                <input type="hidden" name="mctAlarmLogId" value={bundle_mct_alarm_log_id}></input>
                                <Row className="justify-content-between align-items-center no-gutters mt-2 pr-1 pl-1">
                                  <Tooltip 
                                    placement="left"
                                    description="Help"
                                  >
                                    <Button
                                      variant="primary"
                                      style={{
                                        height: "19px",
                                        width: "19px",
                                        // fontSize: "14px",
                                        // fontWeight: "bold",
                                        // lineHeight: "14px",
                                        borderRadius: "50%",
                                        padding: "0",
                                      }}
                                      onClick={() => {
                                        window.Intercom('showArticle', 6192895) 
                                      }}
                                    ><Icon 
                                      className="fas fa-question fa-xs"
                                      style={{
                                        position: "relative",
                                        bottom: "3px",
                                      }}
                                    /></Button>
                                  </Tooltip>
                                  <Tooltip 
                                    placement="left"
                                    description="Send"
                                  >
                                    <Button
                                      id = {`submit-${bundle_mct_alarm_log_id}`} 
                                      variant="primary"
                                      type="submit"
                                      style={{
                                        height: "30px",
                                        width: "30px",
                                        borderRadius: "50%",
                                      }}
                                    ><Icon 
                                      className="fas fa-arrow-right fa-lg"
                                      style={{
                                        position: "relative",
                                        right: "6px",
                                        bottom: "2px"
                                      }}
                                    /></Button>
                                  </Tooltip>
                                </Row>
                              </Form>
                            </VerticalTimelineElement>
                          </VerticalTimeline>
                        </div>
                        : <div className="notifications-container text-dark pt-3 pb-2 pl-3 pr-3 m-1">
                          { notification_count === 0 
                            ? <div className="pb-2">No notifications were sent for this event.</div>
                            : notificationHistory && notificationHistory.data && notificationHistory.data.length > 0
                              ? notificationHistory.data.map((notification, index) => {
                                  let iconClass = "fa-bell";
                                  let iconTooltip = notification.channel.charAt(0).toUpperCase() + notification.channel.slice(1);
                                  switch (notification.channel) {
                                    case "push":
                                      iconClass = "fa-mobile";
                                      break;
                                    case "email":
                                      iconClass = "fa-envelope";
                                      break;
                                    case "sms":
                                      iconClass = "fa-sms";
                                      iconTooltip = "SMS";
                                      break;
                                    case "voice":
                                      iconClass = "fa-phone-volume";
                                  }
                                  return <div className="pb-2" key={index}>
                                    <span className="pr-2 notification-icon">
                                      <Tooltip
                                        placement="left"
                                        description={iconTooltip}
                                      >
                                        <Icon className={`fas ${iconClass} fa-lg`}/>
                                      </Tooltip>
                                    </span>
                                    <span className="pr-2 notification-name">
                                      {
                                        (!notification.first_name && !notification.last_name)
                                          ? "N/A"
                                          : `${notification.first_name ? notification.first_name : ""} ${notification.last_name ? notification.last_name : ""}`
                                      }
                                    </span>
                                    <span className="notification-data">{notification.display_data}</span>
                                  </div>
                                })
                              : <div className="pb-2"><Loading /></div>
                          }
                        </div>
                      }
                    </div>
                    
                  </VerticalTimelineElement>
                );
              }
            )}

            <VerticalTimelineElement
              contentStyle={{
                boxShadow: "none",
                padding: 0,
                background: "transparent",
              }}
              contentArrowStyle={{
                borderRight: "none",
              }}
              className="vertical-timeline-element--work vertical-timeline-element-end text-dark py-4 my-2"
              iconStyle={{ background: "transparent", marginTop: "2em" }}
            >
              <div className="vertical-timeline-element-title text-uppercase font-weight-bold font-16">
                <LoadMore
                  loading={site.loading}
                  timeframe={site.timeframe}
                  onLoadMore={(timeframe) => {
                    dispatch(changeGroupTimeframe({ timeframe }));
                  }}
                />
              </div>
            </VerticalTimelineElement>
          </VerticalTimeline>
        </div>
      </div>
    );
  }
}
Timeline.propTypes = {
  bundle: PropTypes.shape({
    events: PropTypes.array.isRequired,
  }),
  // site: PropTypes.shape({
  //   alarmBundles: PropTypes.array.isRequired
  // })
};
const mapStateToProps = (state, props) => {
  return {
    user: state.user,
    site: getCurrentSite(state, props),
    notificationHistories: state.sites.current.notificationHistories,
    getEventMetaById: createGetEventMetaById(state, props),
  };
};
export default withT(
  connect(mapStateToProps)(Timeline),
  "site_overview.side_pane.events"
);
