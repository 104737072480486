import React from "react";

// import { changeCurrentReportFilter } from "services/redux/actions";

// import { useDispatch } from "react-redux";

import get from "lodash/get";

import {
  getType,
  getEventColour,
} from "services/redux/selectors/reports/scheduled/suspicious";

import EventCell from "components/Common/DataTable/EventCell/";
import SiteNameCell from "components/Common/DataTable/SiteNameCell/";

export default {
  NAME: {
    Cell: SiteNameCell,
  },
  EXPECTED: { show: false },
  ACTUAL: {
    Header: "Recorded Time",
  },
  DIFFERENCE: { show: false },
  TYPE: {
    Header: "Type",
    headerClassName: "header-type",
    id: "type",
    width: 140,
    show: true,
    Cell: ({ original, value }) => {
      //   const dispatch = useDispatch();

      return (
        <EventCell
          event={{
            colour: getEventColour(original.occurrences[0]),
            label: value,
          }}
          onClick={() => {
            /* dispatch(
              changeCurrentReportFilter({
                label: value,
                filterType: "eventType",
                reportType: "suspicious"
              })
            ); */
          }}
        />
      );
    },
    style: {
      padding: "0px",
    },
    accessor: function ({ occurrences }) {
      const status = getType(occurrences[0]);

      return status;
    },
  },
  RESULT: {
    show: false,
    width: 160,
    Header: "Event",
    headerClassName: "event-type",
  },
  EVENT_TEXT: {
    width: 160,
  },

  buildExportArray: ({
    data,
    getEventMeta,
    // getActualFromOccurrence,
    getActualWithTimezone,
    // getDiffFromOccurrence
  }) => {
    let result = [];

    let headers = [
      "Custom ID",
      "Site Name",
      "Group",
      "Site Ref",
      "Occurrences",

      "Event",
      // "Expected Time",
      "Recorded Time",
      // "Difference",
      "Event Text",
      "Occurrence",
    ];

    // Add headers
    result.push(headers);

    data.forEach((row) => {
      const occurrence = get(row, "result.0");
      const event = getEventMeta(occurrence);

      // Push site data first
      result.push([
        row["custom-id"] || "",
        row["site-name"],
        row.group || "",
        row["site-ref"] || "",
        row.occurrences,

        event.label || "",
        // row["expected-time"] || "",
        // getActualFromOccurrence(get(row["actual-time"], 0)),
        getActualWithTimezone(get(row["actual-time"], 0)),
        // getDiffFromOccurrence(get(row["difference"], 0)),
        row["event-text"],
      ]);

      // And show occurrences below
      // const occurrences = get(row, "result");
      // occurrences.forEach((occurrence, index) => {
      //   const event = getEventMeta(occurrence);

      //   result.push([
      //     "",
      //     "",
      //     "",
      //     "",
      //     "",

      //     event.label || "",
      //     // row["expected-time"] || "",
      //     getActualFromOccurrence(get(row["actual-time"], 0)),
      //     // getDiffFromOccurrence(get(row["difference"], 0)),
      //     row["event-text"],
      //     "#" + (index + 1)
      //   ]);
      // });
    });

    return result;
  },
};
