import produce from "immer";
import set from "lodash/set";
import get from "lodash/get";

import merge from "deepmerge";

import { getEnvironment } from "apis/conxtdOut";

import REPORT_IDS from "constants/REPORT_IDS";

const initialState = {};

const parentReportTypes = {
  overnight: "scheduled",
  open: "scheduled",
  close: "scheduled",
  fire: "scheduled",
  panic: "scheduled",
  bundle: "outstanding",
  outstanding: "outstanding",
  suspicious: "scheduled",
  peoplecount: "scheduled",
  peoplecounthourly: "scheduled",
  openclosebyarea: "openclosebyarea", // 09/21 - open close by area
  openclosebyareafull: "openclosebyarea", // 09/21 - open close by area
  openclosedareas: "openclosebyarea", // 09/21 - open close by area
  closedopenareas: "openclosebyarea", // 09/21 - open close by area
};

export default (state = initialState, action) => {
  let { reportType } = action;

  let parent = "default";
  // Determine parent from given child report type
  if (reportType) {
    parent = parentReportTypes[reportType];
  }
  switch (action.type) {
    case "LOAD_REPORTS_OVERVIEW_SUCCESS":
      return produce(state, (draftState) => {
        const reportPaths = [];

        Object.keys(action[action.key]).forEach((reportId) => {
          const report = action[action.key][reportId];

          const key = REPORT_IDS[getEnvironment()][reportId];
          if (!key) {
            console.warn("Missing report ID, skipping:" + reportId);
            return;
          }

          // Creates ["scheduled", "open"]
          const reportPath = key.split(".");
          reportPaths.push(reportPath);

          const currentReport = get(state, key);

          // Set report config, merging
          set(
            draftState,
            key + ".config",
            merge(currentReport.config, report.config)
          );

          // Slight transform for outstanding index
          // because "totals" format isn't on outstanding yet
          if (key === "outstanding.index") {
            // Set report totals
            set(
              draftState,
              key + ".data.pair_bundles",
              report.totals.pair_bundles
            );
            set(
              draftState,
              key + ".data.sites_count",
              report.totals.sites_count
            );
            set(draftState, key + ".loading", false);
          } else if (key === "openclosebyarea.index") {
            // 09/21 - open close by area
            // Set report overview details
            set(draftState, key + ".data", report.totals);
          } else if (reportPath[0] === "openclosebyarea") { 
            // 09/21 - open close by area 
            // doesn't need totals or anything else for the overview
            set(draftState, key + ".loading", false);
          } else {
            // Set report totals
            set(draftState, key + ".data.totals", report.totals);
            set(draftState, key + ".data.combined_hourly", report.totals.combined_hourly); // 08/21 to allow for hourly type reports
            set(draftState, key + ".loading", false);
          }
        });

        // Set available reports (report paths) for overview
        set(draftState, "overview." + action.key + ".reportPaths", reportPaths);
      });

    case "CHANGE_CURRENT_REPORT_FILTER":
      return produce(state, (draftState) => {
        const { filterType } = action;

        const show =
          state[parent][reportType].filter[filterType].show[action.show] ||
          null;

        // Get visible filters
        const visibleFilters = Object.keys(
          state[parent][reportType].filter.eventCount.show
        ).filter((f) => state[parent][reportType].filter[filterType].show[f])
          .length;

        // Reset when all filters are true
        if (visibleFilters === 4 && !show) {
          draftState[parent][reportType].filter[filterType].show = {};
          return;
        }

        // Toggle visibility
        if (show === true) {
          delete draftState[parent][reportType].filter[filterType].show[
            action.show
          ];
        } else {
          draftState[parent][reportType].filter[filterType].show[
            action.show
          ] = true;
        }
      });
    case "TOGGLE_DATATABLE_FILTERED":
      let { filter } = action;

      // Get index of the specified col filter
      const resultColIndex = state[parent][
        reportType
      ].DataTable.filtered.indexOf(
        state[parent][reportType].DataTable.filtered.find(
          (f) => f.id === filter.id
        )
      );

      // Check for specified column filter
      let filterExists = resultColIndex !== -1;

      // Specified column filter exists
      if (filterExists) {
        // Filter is not equal to toggle value
        //  so set to toggle value
        if (
          state[parent][reportType].DataTable.filtered[resultColIndex].value !==
          filter.value
        ) {
          const filtered = [...state[parent][reportType].DataTable.filtered];
          filtered[resultColIndex] = filter;
          return {
            ...state,
            [parent]: {
              ...state[parent],
              [reportType]: {
                ...state[parent][reportType],
                DataTable: {
                  // Filter based on index
                  filtered,
                },
              },
            },
          };
        }

        // Remove filter
        return {
          ...state,
          [parent]: {
            ...state[parent],
            [reportType]: {
              ...state[parent][reportType],
              DataTable: {
                // Filter based on index
                filtered: state[parent][reportType].DataTable.filtered.filter(
                  (f, i) => i !== resultColIndex
                ),
              },
              // Resetting expanded
              expanded: {},
            },
          },
        };
      }

      // Filter doesn't exist, add it
      return {
        ...state,
        [parent]: {
          ...state[parent],
          [reportType]: {
            ...state[parent][reportType],
            DataTable: {
              filtered: [
                ...state[parent][reportType].DataTable.filtered,
                filter,
              ],
            },
            // Resetting expanded
            expanded: {},
          },
        },
      };

    case "CHANGE_DATATABLE_FILTERED":
      return {
        ...state,
        [parent]: {
          ...state[parent],
          [reportType]: {
            ...state[parent][reportType],
            DataTable: {
              filtered: action.filtered,
            },
          },
        },
      };

    // Performance is probably not as good?
    // return produce(state, draftState => {
    //   draftState[parent][reportType].DataTable.filtered = action.filtered;
    // });
    default:
      return state;
  }
};
