import React from "react";

import { Bar } from "react-chartjs-2";

/**
 * BarChart - a chart js bar chart that accepts the format:
 * values:{
 *  name,
 *  color,
 *  value
 * }
 * total is a total to calculate a percentage for (currently
 * unused)
 */

export default ({ values, total }) => (
  <Bar
    // labels={values.map(v => v.name)}
    data={{
      labels: values.map(v => v.name),

      datasets: [
        {
          type: "bar",
          label: "dataset",
          fill: true,
          // backgroundColor: ["#000000"],
          borderColor: values.map(v => v.color),
          backgroundColor: values.map(v => v.color),
          data: values.map(v => parseInt(v.value))
        }
      ]
    }}
    options={{
      responsive: true,
      legend: { display: false },
      layout: {
        padding: {
          // Fixes bottom labels being cut off
          bottom: 10
        }
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true
            }
          }
        ]
      }
    }}
  />
);
