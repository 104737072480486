import React from "react";
import { connect } from "react-redux";

import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file 

import { Button } from "react-bootstrap";

import { weekStart } from "services/locale/constants";

import { loadEventMetaBackgroundStart } from "services/redux/actions/";

import { loadSitesBackgroundStart } from "services/redux/actions/sites";

import { 
  loadCustomReportStart, 
  loadCustomReportBundlesStart,
} from "services/redux/actions/customReports";

import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";
import { getGroupFromRoute } from "services/redux/selectors/groups";
import { getGroupOptions, getBundleOptions, getSiteOptions, createGetSitesOptionsByGroup } from "services/redux/selectors/customReports";

import DataTable from "./DataTable";

import Suggestions from "./Suggestions";

import DropdownTreeSelectCustom from "components/Common/DropdownTreeSelect/DropdownTreeSelectCustom";

import Loading from "components/Loading";

import Alert from "components/Common/Alert";

import moment from "services/locale/momentInit.js";

import _ from "lodash";

import "./CustomReports.scss";

const WEEK_START = weekStart();

class CustomReports extends React.Component {

  state = {
    selectionRange: [
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      }
    ],
    // group options come in directly on the props
    bundleOptions: [], // bundle options need to be stored in local state as we need to change the 'checked' properties when clearing selections
    siteOptions: [], // site options need to be stored in local state as they change according to group selection and when clearing selections
    selectedGroupOption: null,
    selectedBundleOptions: null,
    selectedSiteOptions: null,
    // previousQueries: [],
    selectedSuggestionsCardId: null,
  }

  componentDidMount() {
    const { enterprise, group, siteOptions, getSiteOptionsByGroup, bundleOptions } = this.props;

    this.props.dispatch(
      loadEventMetaBackgroundStart({})
    );

    this.props.dispatch(
      loadCustomReportBundlesStart({})
    );

    this.props.dispatch(
      loadSitesBackgroundStart({
        enterprise_id: enterprise.id,
      })
    );

    this.setState({ bundleOptions: [ ...bundleOptions ] });

    const groupId = group ? group.id : null;
    if (groupId) {
      this.setState({ siteOptions: [ ...getSiteOptionsByGroup(groupId) ] });
    } else {
      this.setState({ siteOptions: [ ...siteOptions ] });
    }

    // //test
    // this.setState({ 
    //   previousQueries: [ 
    //     ...this.state.previousQueries, {
    //       queryDateTime: new date(),
    //       queryRequest: ""
    //     } 
    //   ] 
    // });
  }

  componentDidUpdate(prevProps) {
    const { siteOptions, getSiteOptionsByGroup, bundleOptions } = this.props;
    
    // we can't use the bundle options directly from the props because we need to be able to update the 'checked' properties 
    // when implementing the 'clear all' functionality.
    // therefore, when the bundle options come in on the props, store them in the local state for use.
    if (!_.isEqual(bundleOptions, prevProps.bundleOptions)) {
      this.setState({ bundleOptions: [ ...bundleOptions ] });
    }

    if (!_.isEqual(siteOptions, prevProps.siteOptions)) {
      const groupId = this.state.selectedGroupOption;
      if (groupId) {
        this.setState({ siteOptions: [ ...getSiteOptionsByGroup(groupId) ] });
      } else {
        this.setState({ siteOptions: [ ...siteOptions ] });
      }
    }
  }

  // Group Selection
  handleGroupSelectionChange = (selectedNodes) => {
    let groupId = selectedNodes[0] ? selectedNodes[0].value : null;
    this.setState({ selectedGroupOption: groupId });
    
    // filter the sites offered according to the selected group
    if (groupId === this.props.enterprise.root_group_id) {
      groupId = null;
    }
    this.setState({ siteOptions: this.props.getSiteOptionsByGroup(groupId) });
    this.setState({ selectedSiteOptions: null });
  };

  // Bundle Selection
  handleBundleSelectionChange = (selectedNodes) => {
    this.setState({ selectedBundleOptions: selectedNodes ? selectedNodes : null });

    // remove the selected suggestions card id so the Suggestions component stops highlighing it
    this.setState({ selectedSuggestionsCardId: null });
  };

  handleBundleSelectionClearAll = () => {
    this.setState({ selectedBundleOptions: null });

    // remove the selected suggestions card id so the Suggestions component stops highlighing it
    this.setState({ selectedSuggestionsCardId: null });
  }

  // Site Selection
  handleSiteSelectionChange = (selectedNodes) => {
    this.setState({ selectedSiteOptions: selectedNodes ? selectedNodes : null });
  };

  handleSiteSelectionClearAll = () => {
    this.setState({ selectedSiteOptions: null });
  }

  // Suggestions
  handleApplySuggestions = (selectedSuggestionsCardId, bundleSuggestions, eventSuggestions) => {
    const { bundleOptions } = this.state;

    // set the selected bundle/event options according to the suggestions
    const selectedBundleOptions = [];
    bundleSuggestions.forEach((bundleSuggestion) => {
      selectedBundleOptions.push({ value: `bundle-${bundleSuggestion}`} );
    });
    eventSuggestions.forEach((eventSuggestion) => {
      selectedBundleOptions.push({ value: eventSuggestion} );
    });
    this.setState({ selectedBundleOptions: selectedBundleOptions });

    // make sure the dropdown displays the selected options
    this.setState({ 
      bundleOptions: bundleOptions.map((bundleOption) => {
        let bundleExpanded = false;

        const bundleSelected = bundleSuggestions.findIndex((bundleSuggestion) => String(bundleSuggestion) === bundleOption.value.substring(7)) !== -1;
        const children = bundleSelected ?
          bundleOption.children.map((eventOption) => {
            return {
              ...eventOption,
              checked: true,
            }
          }) :
          bundleOption.children.map((eventOption) => {
            const eventSelected = eventSuggestions.indexOf(eventOption.value) !== -1;
            bundleExpanded = bundleExpanded || eventSelected;
            return {
              ...eventOption,
              checked: eventSelected,
            }
          });

        return {
          ...bundleOption,
          checked: bundleSelected,
          expanded: bundleExpanded,
          children,
        }
      }) 
    });

    // set the id of the selected suggestions card so the Suggestions component knows which one to highlight
    this.setState({ selectedSuggestionsCardId: selectedSuggestionsCardId });
  }

  handleGetReportClick = () => {
    const { enterprise, group } = this.props;
    const { selectionRange, selectedGroupOption, selectedBundleOptions, selectedSiteOptions, bundleOptions } = this.state;

    let group_id = selectedGroupOption || (group && group.id) || enterprise.root_group_id;
    if (group_id === "root") {
      group_id = enterprise && enterprise.root_group_id;
    }

    const startDate = new moment(selectionRange[0].startDate).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    const endDate = new moment(selectionRange[0].endDate).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

    const dateMillisecondsDiff = endDate.valueOf() - startDate.valueOf(); 
    const dateDaysDiff = dateMillisecondsDiff / (1000 * 60 * 60 * 24); 
    const dateRangeNumberOfDays = dateDaysDiff + 1;

    if (
      ((!selectedSiteOptions || selectedSiteOptions.length === 0) && (!selectedBundleOptions || selectedBundleOptions.length === 0))
      && (dateRangeNumberOfDays > 31)
    ) {
      Alert({
        text: "Date range must be less than 1 month when searching on whole group. To search up to 1 year, select specific sites or events.",
        icon: "warning",
      });
    } else if (dateRangeNumberOfDays > 367) {
      Alert({
        text: "Date range must be less than 1 year.",
        icon: "warning",
      });
    } else {
      // extract the events from the selected bundle/event options
      const eventOptions = [];
      if (selectedBundleOptions) {
        selectedBundleOptions.forEach((bundleOption) => {
          if (String(bundleOption.value).substring(0, 6) === "bundle") {
            const bundle = _.find(bundleOptions, ["value", bundleOption.value]);
            bundle.children.forEach((event) => {
              eventOptions.push(event.value)
            })
          } else {
            eventOptions.push(bundleOption.value);
          }
        })
      }
      this.props.dispatch(
        loadCustomReportStart({ 
          enterprise_id: enterprise.id,
          group_id, 
          params: {
            "start_date": new moment(selectionRange[0].startDate).set({ hour: 0, minute: 0, second: 0 }).format("YYYY-MM-DD HH:mm:ss"),
            "end_date": new moment(selectionRange[0].endDate).set({ hour: 23, minute: 59, second: 59 }).format("YYYY-MM-DD HH:mm:ss"),
            "sites": selectedSiteOptions ? selectedSiteOptions.map(siteOption => siteOption.value) : [],
            "events": eventOptions,
            "text": document.getElementById("custom-report-text").value
          }
        })
      );
    }
  }

  render() {
    const { enterprise, loadingCustomReport, groupOptions, user } = this.props;
    const { selectionRange, siteOptions } = this.state;

    // No enterprises loaded yet
    if (!enterprise) {
      return null;
    };

    let firstDayOfWeek = WEEK_START;
    if (enterprise.calendar_config && (enterprise.calendar_config === "advanced") && (enterprise.start_day || (enterprise.start_day === 0))){
      firstDayOfWeek = enterprise.start_day;
    }
    if (enterprise.calendar_config && (enterprise.calendar_config === "custom") && (enterprise.first_day_of_year || (enterprise.first_day_of_year === 0))){
      firstDayOfWeek = enterprise.first_day_of_year;
    }

    let minDate = new Date();
    minDate.setMonth(minDate.getMonth() - 24);
    minDate.setDate(1);
    const maxDate = new Date(); //today

    return (
      <>
        <div className="row">
          <div className="col-md-12">
            <div className="card m-b-30 visual-data">
              <div className="card-body">
                <label className="d-block">Date Range</label>
                <DateRangePicker
                  editableDateInputs={true}
                  onChange={dateRange => {
                    this.setState({ selectionRange: [dateRange.selection] })}
                  }
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  ranges={selectionRange}
                  months={2}
                  direction="horizontal"
                  preventSnapRefocus={true}
                  calendarFocus="backwards"
                  weekStartsOn={firstDayOfWeek}
                  // weekdayDisplayFormat="EEEEEE"
                  minDate={minDate}
                  maxDate={maxDate}
                />
                <div className="row" style={{ maxWidth: "930px" }}>
                  <div className="col-5">
                    <label className="d-block pt-3">Group</label>
                    <DropdownTreeSelectCustom
                      options={groupOptions} 
                      onChange={this.handleGroupSelectionChange} 
                      mode="radioSelect"
                    />
                    <label className="d-block pt-3">Sites</label>
                    <div className="d-flex with-clear-all">
                      <DropdownTreeSelectCustom
                        options={siteOptions} 
                        onChange={this.handleSiteSelectionChange} 
                        clearable
                        onClearAll={this.handleSiteSelectionClearAll}
                        mode="multiSelect"
                      />
                    </div>
                    <label className="d-block pt-3">Events</label>
                    <div className="d-flex with-clear-all">
                      <DropdownTreeSelectCustom
                        options={this.state.bundleOptions} 
                        onChange={this.handleBundleSelectionChange}
                        clearable
                        onClearAll={this.handleBundleSelectionClearAll}
                        mode="multiSelect"
                      />
                    </div>
                    <label className="d-block pt-3">Text</label>
                    <input className="form-control" type="text" id="custom-report-text"></input>
                  </div>
                  <div className="col-7 pt-3">
                    {(user.environment !== "india") && <Suggestions
                      applySuggestions={this.handleApplySuggestions}
                      selectedSuggestionsCardId={this.state.selectedSuggestionsCardId}
                    />}
                  </div>
                </div>
                <div className="pt-4">
                  <Button
                    variant="primary"
                    onClick={this.handleGetReportClick}
                  >Generate</Button>
                  <span>&nbsp;&nbsp;{loadingCustomReport && <Loading left />}</span>
                </div>
                {/* <div style={{float: "right"}}>
                  <select name="previous-custom-report-queries" id="previous-custom-report-queries">
                    <option value="dt1">Date Time 1</option>
                    <option value="dt2">Date Time 2</option>
                  </select>
                  <Button>Run previous query</Button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <DataTable />
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    enterprise: getEnterpriseFromRoute(state, props),
    group: getGroupFromRoute(state, props),
    user: state.user,
    groupOptions: getGroupOptions(state, props),
    bundleOptions: getBundleOptions(state, props),
    siteOptions: getSiteOptions(state, props),
    getSiteOptionsByGroup: createGetSitesOptionsByGroup(state, props),
    loadingCustomReport: state.customReports.loadingCustomReport,
  };
};
export default connect(mapStateToProps)(CustomReports);