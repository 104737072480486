import { createSelector } from "reselect";

import { createGetEventMetaById } from "services/redux/selectors/eventMeta";
import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";
import { getGroupFromRoute, getGroups, getCustomRef } from "services/redux/selectors/groups";
import { getSites, getSiteBySudoSiteId, getSitesByGroup } from "services/redux/selectors/sites";

import moment from "services/locale/momentInit.js";

export const getCustomReports = (state) => state.customReports; 

export const getCustomReportBundles = (state) => state.customReports.bundles; 

export const getCustomReportData = createSelector(
  [getCustomReports, createGetEventMetaById, getSites],
  (customReports, getEventMetaById, sites) => {
    let resultingCustomReportData = customReports.data.map((customReportItem) => {
      // get the event meta data for the report item
      let customReportItemEventMeta = getEventMetaById(customReportItem.conxtd_alarm_id);

      // get the site data for the report item
      let customReportItemSite = getSiteBySudoSiteId(sites, customReportItem.sudo_site_id);

      return {
        ...customReportItem,
        event_meta: { ...customReportItemEventMeta },
        site: { ...customReportItemSite },
      };
    });

    return resultingCustomReportData;
  }
);

export const getActualWithTimezone = (occurrence) => {
  const localDate = new moment(occurrence.acked);

  if (occurrence.timezone) {
    const timezoneDate = new moment.utc(localDate).tz(occurrence.timezone);
    const localTimezoneText = moment.tz.guess();
    const timezoneText = occurrence.timezone === localTimezoneText ? "" : occurrence.timezone;
  
    return `${timezoneDate.format(occurrence.format)} ${timezoneText}`
  } else {
    return `${localDate.format(occurrence.format)}`
  }
};

export const getGroupOptions = createSelector(
  [getEnterpriseFromRoute, getGroupFromRoute, getGroups],
  (enterprise, group, groups) => {

    const getGroupOptionsFromArray = (arrayOfGroups) => {
      return arrayOfGroups.map((group) => {
        return {
          label: group.name,
          value: group.id,
          children: getGroupOptionsFromArray(group.children),
        }
      })
    }

    const getGroupOptions = (arrayOfGroups, group, enterprise) => {
      let currentSubGroups = arrayOfGroups;

      if (enterprise.root_group_id !== group.id){
        const checkArrayForCurrentGroup = (arrayOfGroups) => {
          for (let i=0; i<arrayOfGroups.length; i++) {
            if (arrayOfGroups[i].id === group.id) {
              currentSubGroups = arrayOfGroups[i].children;
              break;
            }
            checkArrayForCurrentGroup(arrayOfGroups[i].children);
          }
        }
        checkArrayForCurrentGroup(arrayOfGroups);
      }

      return [{
        label: group.name,
        value: group.id,
        checked: true,
        expanded: true,
        children: getGroupOptionsFromArray(currentSubGroups)
      }]
    }

    return getGroupOptions(
      groups, 
      group || {
        name: enterprise.name,
        id: enterprise.root_group_id,
        children: groups,
      }, 
      enterprise
    );
  }
);

export const getBundleOptions = createSelector(
  [getCustomReportBundles],
  (bundles) => {
    return bundles.filter((bundle) => {
      return bundle.id !== 2; // don't show latest alarms bundle as this just contains duplicates of events in other bundles
    }).map((bundle) => {
      return {
        value: `bundle-${bundle.id}`,
        label: bundle.title,
        children: bundle.conxtd_alarms.map((alarm) => {
          return {
            value: alarm.id,
            label: alarm.caption || "",
          }
        }).filter((alarm) => {
          return alarm.label ? true : false;
        }).sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
      }
    }).sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
  }
);

export const getSiteOptions = createSelector(
  [(state) => state, getSites],
  (state, sitesList) => {
    // passing null in the third parameter of getSitesByGroup gives the sites for the root group
    return getSitesByGroup(state, sitesList, null).map((site) => {
      const customRef = getCustomRef(site);
      return {
        value: site.id,
        label: `${customRef} - ${site.name} - ${site.site_ref}`,
      }
    }).sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
  }
);

export const createGetSitesOptionsByGroup = createSelector(
  [(state) => state, getSites, getEnterpriseFromRoute],
  (state, sitesList, enterprise) => {
    return (groupId) => {
      return getSitesByGroup(state, sitesList, enterprise, groupId).map((site) => {
        return {
          value: site.id,
          label: `${site.site_ref} - ${site.name}`,
        }
      }).sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
    }
  }
);