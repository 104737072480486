import React from "react";
import DAYS_AGO_COLORS from "constants/DAYS_AGO_COLORS.json";

import { default as T } from "services/i18n/Translate";

const VAULT_REQUEST_BUNDLE_ID = 21;
const CONFIRMED_ALARM_BUNDLE_ID = 31;

export default {
  bundle: {
    charts: {
      pie: {
        filter: {
          component: "legend",
          type: "eventCount",
          // TODO: translate
          keys: [
            "Up to 24 hours",
            "Up to 2 days",
            "Up to 1 week",
            "Up to 4 weeks",
            "Over 1 month",
          ],
          colors: [...Object.values(DAYS_AGO_COLORS)],
        },
        renderer: (props) => {
          const { filterComponent, pie } = props;

          return (
            <>
              <div className="row pb-2 pie-row w-xl-110">
                <div className="col-xl-7 px-0 my-auto">{pie}</div>
                <div className="col-xl-5 pt-3 pt-xl-0 my-auto">
                  {filterComponent}
                </div>
              </div>
            </>
          );
        },
      },
    },

    /**
     * Determines if we should render a first section for this bundle
     *
     * @param {*} bundle checks against bundle.data.id
     */
    getShowFirstSection: function (bundle) {
      // Hide for vault request and confirmed alarm
      return (
        bundle.data.id !== VAULT_REQUEST_BUNDLE_ID &&
        bundle.data.id !== CONFIRMED_ALARM_BUNDLE_ID
      );
    },
    /**
     * Determines if we should render the live refresh indicator
     *
     * @param {*} bundle checks against bundle.data.id
     */

    getShowLiveRefresh: function (bundle) {
      //  return bundle.data.id !== 21;
      return bundle;
    },

    /**
     * Returns the live refresh interval in seconds
     * 5 seconds for vault request & confirmed alarm, 60 for all others
     *
     * @param {number} bundle_id bundle id to match with
     */
    getLiveRefreshInterval: function (bundle_id) {
      return Number(bundle_id) === VAULT_REQUEST_BUNDLE_ID ||
        Number(bundle_id) === CONFIRMED_ALARM_BUNDLE_ID
        ? 5
        : 60;
    },
    /**
     * Determines whether to show access column for the specified
     * bundle id
     *
     * @param {number} bundle_id the id of the bundle
     */
    getShowAccessColumn: function (bundle_id) {
      return bundle_id === VAULT_REQUEST_BUNDLE_ID;
    },

    /**
     * Determines whether to show action column for the specified
     * bundle id
     *
     * @param {number} bundle_id the id of the bundle
     */
    getShowActionColumn: function (bundle_id) {
      return bundle_id === CONFIRMED_ALARM_BUNDLE_ID;
    },

    firstSection: {
      renderer: (props) => {
        const { pie } = props;

        return (
          <div className="row">
            <div className="col-xl-12 pb-4 pl-xl-0 visual-data">{pie}</div>
          </div>
        );
      },
    },
    noDataAvailableRenderer: () => {
      return (
        <div className="row no-data-available">
          <div className="col-xl-12 pb-4 pl-xl-0 text-center">
            <T>No data available for the selected bundle.</T>
          </div>
        </div>
      );
    },
  },
  index: {
    title: "Outstanding Previous 30 Days",
    overview: {
      // Show on index page
      index: 4,
      className: "col-md-12",
    },
    noDataAvailableRenderer: () => {
      return (
        <div className="col-xl-12">
          <T>No outstanding events.</T>
        </div>
      );
    },
  },
};
