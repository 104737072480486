import moment from "services/locale/momentInit.js";

import _ from "lodash";

export default (state = { lastFetched: {} }, action) => {
  let lastFetched;
  switch (action.type) {
    case "RESTORE_LAST_FETCHED":
      lastFetched = sessionStorage.getItem("lastFetched");
      if (lastFetched) lastFetched = JSON.parse(lastFetched);
      return { ...state, lastFetched };
    case "CACHE_ME":
      const cacheSubKey = action.cacheSubKey || "";
      const key = action.cacheKey + "/" + cacheSubKey;
      // Persist into browser storage
      sessionStorage.setItem(
        key,
        JSON.stringify(_.omit(action, ["type", "cacheKey"]))
      );
      // Record that this has just been fetched into browser storage
      lastFetched = {
        ...state.lastFetched,
        [key]: new moment().valueOf()
      };
      sessionStorage.setItem("lastFetched", JSON.stringify(lastFetched));

      return {
        ...state,
        lastFetched
      };
    default:
      return state;
  }
};
