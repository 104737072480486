/**
 * 09/21 - open close by area
 * autocomplete event filter for perimeter field in data table
 */

import React from "react";

import FilterSelect from "components/Common/Dropdown/FilterSelect";

export default ({ filter, onChange, column }) => {
  const { getEventMetaById } = column;

  const options = [];

  const closeEvent = getEventMetaById(9);
  const openEvent = getEventMetaById(10);

  options.push({
    name: closeEvent.caption,
    color: closeEvent.colour,
    id: closeEvent.id,
    icon: closeEvent.icon,
  });

  options.push({
    name: openEvent.caption,
    color: openEvent.colour,
    id: openEvent.id,
    icon: openEvent.icon,
  });

  return (
    <FilterSelect
      onChange={onChange}
      value={filter ? filter.value : ""}
      options={options}
    />
  );
};
