import { watchGetSites } from "./siteList";
import { watchGetGroupsList } from "./dropDownGroupsList";
import { watchGetSiteDetail } from "./siteDetail";
import { watchGetGroupStructure } from "./groupStructure";
import { watchGetCounts } from "./mainPageCount";
import { watchGetUserActions } from "./userActions";
import { watchGetIntegrations } from "./integrations";
import { watchIntegrationConnect } from "./integrationConnect";
import { watchGetBrivoSites } from "./brivoIntegration";
import { watchGetBrivoLinkedSites } from "./brivoIntegration";
import { watchGetEventPairMaps } from "./priorityEventsManagement";
import { watchGetEventPairList } from "./priorityEventsManagement";
import { watchLoadPinMaps } from "./pinMaps";

import cacheIndexes from "../cacheIndexes/";

import { all } from "redux-saga/effects";

export function* rootSaga() {
  yield all(
    [
      watchGetSites(),
      watchGetGroupsList(),
      watchGetSiteDetail(),
      watchGetGroupStructure(),
      watchGetCounts(),
      watchGetUserActions(),
      watchGetIntegrations(),
      watchIntegrationConnect(),
      watchGetBrivoSites(),
      watchGetBrivoLinkedSites(),
      watchGetEventPairList(),
      watchGetEventPairMaps(),
      watchLoadPinMaps(),
    ],
    cacheIndexes.cacheMe
  );
}
