import React from "react";

export default props => (
  <svg version="1.1" viewBox="-41 0 1132 1132.5" width="100%" height="100%">
    <g id="surface1">
      <path
        fill={props.color}
        d="M 505.324219 585.777344 L 544.378906 585.777344 L 544.378906 663.878906 L 505.324219 663.878906 Z M 505.324219 585.777344 "
        // style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;"
      />
      <path
        fill={props.color}
        d="M 505.324219 741.984375 L 544.378906 741.984375 L 544.378906 820.085938 L 505.324219 820.085938 Z M 505.324219 741.984375 "
        // style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;"
      />
      <path
        fill={props.color}
        d="M 505.324219 898.191406 L 544.378906 898.191406 L 544.378906 976.292969 L 505.324219 976.292969 Z M 505.324219 898.191406 "
        // style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;"
      />
      <path
        fill={props.color}
        d="M 505.324219 1054.398438 L 544.378906 1054.398438 L 544.378906 1132.5 L 505.324219 1132.5 Z M 505.324219 1054.398438 "
        // style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;"
      />
      <path
        fill={props.color}
        d="M 623.136719 589.925781 L 660.859375 579.820312 L 681.066406 655.261719 L 643.355469 665.367188 Z M 623.136719 589.925781 "
        // style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;"
      />
      <path
        fill={props.color}
        d="M 663.554688 740.800781 L 701.277344 730.695312 L 721.492188 806.136719 L 683.777344 816.238281 Z M 663.554688 740.800781 "
        // style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;"
      />
      <path
        fill={props.color}
        d="M 704.011719 891.699219 L 741.734375 881.59375 L 761.945312 957.035156 L 724.226562 967.140625 Z M 704.011719 891.699219 "
        // style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;"
      />
      <path
        fill={props.color}
        d="M 744.417969 1042.574219 L 782.132812 1032.46875 L 802.347656 1107.902344 L 764.640625 1118.011719 Z M 744.417969 1042.574219 "
        // style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;"
      />
      <path
        fill={props.color}
        d="M 742.242188 594.628906 L 776.085938 575.109375 L 815.117188 642.753906 L 781.292969 662.277344 Z M 742.242188 594.628906 "
        // style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;"
      />
      <path
        fill={props.color}
        d="M 820.347656 729.902344 L 854.167969 710.375 L 893.222656 778.019531 L 859.394531 797.539062 Z M 820.347656 729.902344 "
        // style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;"
      />
      <path
        fill={props.color}
        d="M 898.449219 865.210938 L 932.273438 845.683594 L 971.34375 913.320312 L 937.5 932.847656 Z M 898.449219 865.210938 "
        // style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;"
      />
      <path
        fill={props.color}
        d="M 976.574219 1000.480469 L 1010.394531 980.953125 L 1049.449219 1048.59375 L 1015.625 1068.117188 Z M 976.574219 1000.480469 "
        // style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;"
      />
      <path
        fill={props.color}
        d="M 368.628906 656.167969 L 388.84375 580.738281 L 426.554688 590.84375 L 406.339844 666.28125 Z M 368.628906 656.167969 "
        // style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;"
      />
      <path
        fill={props.color}
        d="M 328.210938 807.058594 L 348.421875 731.613281 L 386.140625 741.730469 L 365.921875 817.167969 Z M 328.210938 807.058594 "
        // style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;"
      />
      <path
        fill={props.color}
        d="M 287.761719 957.9375 L 307.972656 882.496094 L 345.703125 892.605469 L 325.476562 968.046875 Z M 287.761719 957.9375 "
        // style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;"
      />
      <path
        fill={props.color}
        d="M 247.324219 1108.816406 L 267.539062 1033.359375 L 305.253906 1043.472656 L 285.042969 1118.917969 Z M 247.324219 1108.816406 "
        // style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;"
      />
      <path
        fill={props.color}
        d="M 234.566406 643.660156 L 273.617188 576.011719 L 307.441406 595.539062 L 268.394531 663.175781 Z M 234.566406 643.660156 "
        // style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;"
      />
      <path
        fill={props.color}
        d="M 156.457031 778.929688 L 195.507812 711.289062 L 229.328125 730.8125 L 190.277344 798.457031 Z M 156.457031 778.929688 "
        // style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;"
      />
      <path
        fill={props.color}
        d="M 78.359375 914.210938 L 117.410156 846.566406 L 151.234375 866.09375 L 112.1875 933.730469 Z M 78.359375 914.210938 "
        // style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;"
      />
      <path
        fill={props.color}
        d="M 0.25 1049.484375 L 39.296875 981.847656 L 73.121094 1001.375 L 34.070312 1069.011719 Z M 0.25 1049.484375 "
        // style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;"
      />
      <path
        fill={props.color}
        d="M 485.800781 0 L 563.902344 0 L 563.902344 351.464844 L 485.800781 351.464844 Z M 810.179688 468.621094 L 239.515625 468.621094 L 265.5625 390.515625 L 784.121094 390.515625 Z M 810.179688 468.621094 "
        // style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;"
      />
    </g>
  </svg>
);
