import React from "react";
import { connect } from "react-redux";

import { Button, Modal, Form } from "react-bootstrap";

import { getIntegrationsStart } from "services/redux/actions/enterpriseManager";

import Alert from "components/Common/Alert";

import Loading from "components/Loading";

import conxtdOut from "apis/conxtdOut";

class AddBrivoApiKeyModal extends React.Component {
  state={
    loading: false,
  }

  render() {
    const { enterpriseId, integrationId } = this.props;
    const { loading } = this.state;

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-60w"
        centered
      >
        <Form onSubmit={(e) => {
          e.preventDefault();

          this.setState({
            loading: true
          });

          conxtdOut.post("/Brivo/apiKey", {
              "enterprise_id": enterpriseId,
              "integration_id": integrationId,
              "api_key": e.target.api_key.value
          }).then(({data}) => {
            this.setState({
              loading: false
            });
            console.log(data);
            if (data.code === 403) {
              Alert({
                text:
                  data.error + ". Please try again.",
                icon: "error",
                timerProgressBar: true,
                timer: 2000,
              });
            } else {
              this.props.dispatch(
                getIntegrationsStart({
                  enterprise_id: enterpriseId,
                })
              );
              Alert({
                text:
                  "Api key validated",
                icon: "success",
                timerProgressBar: true,
                timer: 2000,
              });
              this.props.onHide();
            }
          }).catch(error => {
            this.setState({
              loading: false
            });
            console.log(error);
          });
        }}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Please enter your API key
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Label>Api Key</Form.Label>
            <Form.Control
              type="text"
              name="api_key"
              required
            />
          </Modal.Body>
          <Modal.Footer>
            <div className="container">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  {loading && <Loading left />}
                </div>
                <div className="d-flex justify-content-end">
                  <Button
                    className="mr-2 p-2"
                    variant="primary"
                    onClick={this.props.onHide}
                  >
                    Cancel
                  </Button>
                  <Button className="p-2" variant="success"  type="submit">
                    Connect
                  </Button>
                </div>
              </div>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }
}


const mapStateToProps = (state, props) => {
  return {};
};

export default connect(mapStateToProps)(AddBrivoApiKeyModal);