import React from "react";

export default props => (
  <svg
    id="Capa_1"
    x="0px"
    y="0px"
    width="100%"
    height="100%"
    viewBox="0 0 390 390">
    <g>
      <g fill={props.color}>
        <path
          d="M365,95h-70.855l-15.1-40.267C276.85,48.878,271.253,45,265,45H125c-6.253,0-11.85,3.878-14.045,9.733
          L95.855,95H25c-13.807,0-25,11.193-25,25v200c0,13.807,11.193,25,25,25h340c13.807,0,25-11.193,25-25V120
          C390,106.193,378.807,95,365,95z M195,125c52.383,0,95,42.617,95,95s-42.617,95-95,95s-95-42.617-95-95S142.617,125,195,125z"
        />
        <path
          d="M130,220c0,35.841,29.159,65,65,65s65-29.159,65-65s-29.159-65-65-65S130,184.159,130,220z"
        />
      </g>
    </g>
  </svg>
);
