import { call, takeLatest } from "redux-saga/effects";
import { actionWatcher } from "services/redux/sagas/common/";
import * as links from "services/redux/sagas/links";

import {
  loadCompanyFilterDataStart,
  loadCompanyFilterDataSuccess,
  loadCompanyFilterDataFailed,
  loadCompanyFilterDataCancel,
  loadCompanyStart,
  loadCompanySuccess,
  loadCompanyFailed,
  loadCompanyCancel,
} from "services/redux/actions/company";
  
import conxtdOut from "apis/conxtdOut";

// one-off saga for loading company filter data

export function* watchLoadCompanyFilterData() {

  yield actionWatcher({
    actions: {
      start: loadCompanyFilterDataStart,
      failed: loadCompanyFilterDataFailed,
      success: loadCompanyFilterDataSuccess,
      cancel: loadCompanyFilterDataCancel,
    },
    saga: loadCompanyFilterDataRe,
  });
}

// https://mars.conxtd.com/out_api/Authake/getFilters
export function* loadCompanyFilterDataRe(action) {
  try {
    const { company_id } = action;

    if (!company_id) 
      throw new Error(
        "company_id is required for loading company filters"
      );

    const url = `/Authake/getFilters/${company_id}`;

    const result = yield call(conxtdOut.get, url, action.params);

    return result;
  } catch (e) {
    // throw new Error(e);
    console.error(e);
  }
}

// one-off saga for loading company

export function* watchLoadCompany() {
  yield actionWatcher({
    actions: {
      start: loadCompanyStart,
      failed: loadCompanyFailed,
      success: loadCompanySuccess,
      cancel: loadCompanyCancel,
    },
    saga: loadCompanyRe,
  });
}

// https://mars.conxtd.com/out_api/Authake/getSites/31/1
export function* loadCompanyRe(action) {
  try {
    const { company_id } = action;

    const limit = action.pageSize || 20;
    const page = action.requestPageNumber || 1;

    if (!company_id) 
      throw new Error(
        "company_id is required for loading company"
      );

    let paramString = "";
    action.params && Object.entries(action.params).forEach((param) => {
      paramString = `${paramString}&${param[0]}=${param[1]}`
    })

    const url = `/Authake/getSites/${company_id}?limit=${limit}&page=${page}${paramString}`;

    const result = yield call(conxtdOut.get, url);

    return result;
  } catch (e) {
    // throw new Error(e);
    console.error(e);
  }
}