import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Form, Col } from "react-bootstrap";

import Loading from "components/Loading";
import { withT } from "services/translation/";

import { loadIntegrationLocationsStart } from "services/redux/actions";

function AddLocationForm({
    selectLocation,
    loadAvailableLocations,
    camera_integration_site_id,
    loading,
    locations,
    t
}) {
    useEffect(() => {
        loadAvailableLocations({ camera_integration_site_id });
    }, []);

    return (
        <>
        <Form.Group controlId="formGroupSelectLocation" as={Col} md="6">
            <Form.Label>{t("available_locations")}</Form.Label>
            {loading && <Loading />}
            <Form.Control
            as="select"
            onChange={e => {
                const location_id = e.target.value;

                selectLocation(
                    locations.find(location => location.location_id === location_id)
                );
            }}>
            <option value="-1">{t("placeholder")}</option>
            {locations &&
            locations.map(({ location_name, location_id }, index) => (
                    <option key={index} value={location_id}>
                        {location_name}
                    </option>
                ))}
            </Form.Control>
        </Form.Group>
        </>
    );
}
AddLocationForm.propTypes = {
    /**
     * @param selectLocation location selection callback
     */
    selectLocation: PropTypes.func.isRequired,

    /**
     * @param loadAvailableLocations api call to load selectable locations
     */
    loadAvailableLocations: PropTypes.func.isRequired,

    /**
     * @param camera_integration_site_id camera integration site
     */
    camera_integration_site_id: PropTypes.number.isRequired,

    /**
     * @param locations list of available cameras from api
     */
    locations: PropTypes.array.isRequired,

    /**
     * @param loading api loading status
     */
    loading: PropTypes.any,
};

const mapStateToProps = state => {
    return {
        locations: state.sites.current.integrations.locations.data,
        loading: state.sites.current.integrations.locations.loading !== false
    };
};

const mapDispatchToProps = {
    loadAvailableLocations: loadIntegrationLocationsStart
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withT(
        AddLocationForm,
        "site_overview.side_pane.manage_integrations.providers.select_location"
    )
);