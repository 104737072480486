import reports from "services/redux/transformers/reports";
import _ from "lodash";
import produce from "immer";

import REPORTS_CONFIG from "constants/Reports/config/";

import merge from "deepmerge";

const reportTypes = ["panic", "fire"];

const exceptionInitial = {
  loading: null,
  data: {
    sites: [],
    totals: []
  },
  DataTable: {
    filtered: []
  },
  config: {}
};
const initialState = {
  default: {
    ...exceptionInitial,
    config: REPORTS_CONFIG["default"]["default"]
  }
};

// Build initial state for each report
reportTypes.forEach(reportType => {
  initialState[reportType] = {
    ...exceptionInitial,
    config: merge(
      REPORTS_CONFIG["default"]["default"],
      REPORTS_CONFIG["exception"][reportType] || {}
    )
  };
});

export default (state = initialState, action) => {
  switch (action.type) {
    case "LOAD_EXCEPTION_REPORT_START":
      // Set loading property
      return produce(state, draftState => {
        _.set(draftState, action.reportType + ".loading", true);
      });
    case "LOAD_EXCEPTION_REPORT_SUCCESS":
      const exceptionReport = action[action.reportType];

      const { config, data } = reports.transformException(
        exceptionReport,
        action.reportType
      );

      return {
        ...state,
        [action.reportType]: {
          ...state[action.reportType],
          data,
          config: merge(state[action.reportType].config, config),
          loading: false
        }
      };
    case "LOAD_REPORTS_OVERVIEW_FAILED":
      // Reset reports when overview fails
      return initialState;
    case "LOGOUT_REQUEST":
      // Reset to initial state when user logs out
      return initialState;
    default:
      return state;
  }
};
