/**
 * Notification rules loader reducer
 */

export default function(state = {}, action) {
  switch (action.type) {
    case "LOAD_NOTIFICATIONS_RULES_START":
      return {
        ...state,
        loading: true
      };
    case "LOAD_NOTIFICATIONS_RULES_FAILED":
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case "LOAD_NOTIFICATIONS_RULES_SUCCESS":
      return {
        ...state,
        loading: false,
        data: action.data.integrations
      };
    default:
      return state;
  }
}
