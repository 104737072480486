import { hot } from "react-hot-loader/root";
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import { appStartup } from "services/redux/actions";
import { connect } from "react-redux";

import Draggable from 'react-draggable';

import LoginPage from "./Auth/LoginPage";
import ResetPassword from "./Auth/ResetPassword";
import Logout from "./Auth/LogoutPage";
import AcceptInvite from "./User/AcceptInvite";
import Identity from "./User/Identity";
import PageNotFound from "./Routing/PageNotFound";
import PrivateRoute from "./Routing/PrivateRoute";
import EnterprisePage from "./Enterprise/Index/Page";
import Companies from "./Companies/Index/Companies";
import Company from "./Companies/Company/Company";
import { Overview } from "./Enterprise/Overview/";
// import ExceptionReportPage from "./Enterprise/Reports/Types/Exception/ExceptionReportPage";
// import ScheduledReportPage from "./Enterprise/Reports/Types/Scheduled/ScheduledReportPage";
import ReportPage from "./Enterprise/Reports/Common/ReportPage";
import OutstandingBundleReportPage from "./Enterprise/Reports/Types/Outstanding/Bundle/OutstandingBundleReportPage";
import ReportsIndexPage from "./Enterprise/Reports/Index/ReportsIndexPage";
import EnterpriseSetup from "./Enterprise/Setup/Setup";
import UserManagement from "./Enterprise/UserManagement/";
import EnterpriseManager from "./Enterprise/EnterpriseManager/";
import AlarmResponse from "./Enterprise/AlarmResponse/"; //alarm-response
import CompletedActionReport from "./Enterprise/AlarmResponse/SOPReports/CompletedAction/CompletedActionReport";
import CustomReports from "./Enterprise/CustomReports/"; 
import ViewGroup from "./Enterprise/EnterpriseManager/GroupLayout/ViewGroup/ViewGroup";
import AddFromRoot from "./Enterprise/EnterpriseManager/GroupLayout/ViewGroup/AddFromRoot/AddFromRoot";
import CreateSites from "./Enterprise/EnterpriseManager/GroupLayout/CreateSites";

import Notifications from "./Enterprise/Notifications/Notifications";
import GroupManagement from "./Enterprise/GroupManagement/GroupManagement";
// import GroupsManagerPage from "./Enterprise/GroupsManager/GroupsManagerPage";
// import FacewatchOverview from "./Enterprise/Reports/Facewatch/FacewatchOverview";
import Settings from "./User/Settings";
import Profile from "./User/Profile/";
import Developers from "./Developers/Developers";
import AppStore from "./Enterprise/AppStore";

import VoiceCallControls from "./Site/SiteView/SiteOverview/VoiceCalls/VoiceCallControls";
import { InjectEvents } from "./Enterprise/InjectEvents";

import ConnectingTether from "./OAuth/Tether/ConnectingTether";
import ConnectingBrivo from "./OAuth/Brivo/ConnectingBrivo";

import OutLocks from "./OutLocks/";
import Tether from "./Test/Tether";

import "scss/zinzer/style.scss";
import "./App.scss"

class App extends React.Component {
  state = {
    hasError: false,
  };
  componentWillMount() {
    // Dispatch startup event
    this.props.dispatch(appStartup());

    if (localStorage.getItem("navCollapsed") === "true") {
      document.body.classList.toggle("fixed-left-void");
      document.getElementById("wrapper").classList.toggle("enlarged");
    }
    if (localStorage.getItem("darkMode") === "true") {
      document.body.classList.toggle("darkmode");
    }
  }
  componentDidMount() {
    // Remove preload class
    setTimeout(() => {
      document.body.classList.toggle("preload");
    }, 1000);
  }

  render() {
    //  console.log(this.props.browser.mediaType);
    return (
      <>
        <Draggable>
          <div id="call-controls-draggable">
            <VoiceCallControls />
          </div>
        </Draggable>
        <Draggable>
          <div id="inject-events-draggable">
            <InjectEvents />
          </div>
        </Draggable>
        <Router>
          <Switch>
            <Route path="/login/:env" component={LoginPage} />
            <Route path="/login/" component={LoginPage} />
            <Route
              path="/notification-manager"
              component={() => {
                window.location =
                  "https://intercom.help/conxtd/en/articles/3035837-push-notifications";
                return null;
              }}
            />
            <Route path="/accept-invite/:token" component={AcceptInvite} />
            <Route path="/identity/:sessionId" component={Identity} />
            <Route path="/reset-password/:token" component={ResetPassword} />
            <Route path="/reset-password" component={ResetPassword} />
            <Route
              path="/test-tether"
              exact
              component={Tether}
              user={this.props.user}
            />
            <Route path="/app-store" exact component={AppStore} />
            <PrivateRoute
              path="/enterprise/"
              exact
              component={EnterprisePage}
              user={this.props.user}
            />
            <PrivateRoute
              path="/companies/"
              exact
              component={Companies}
              user={this.props.user}
              disableBreadcrumbs={true}
            />
            <PrivateRoute
              path="/companies/company/:company_id"
              exact
              component={Company}
              user={this.props.user}
              disableBreadcrumbs={true}
            />
            <PrivateRoute
              // Developer options
              path={`/developers`}
              component={Developers}
              user={this.props.user}
              exact
            />

            {/* OutLocks Demo */}
            <PrivateRoute
              path={`/enterprise/:enterprise_id/:group_id/reports/outlocks`}
              component={OutLocks}
              user={this.props.user}
              exact
            />
            {/* Reports */}
            <PrivateRoute
              path={`/enterprise/:enterprise_id/:group_id/reports`}
              component={ReportsIndexPage}
              user={this.props.user}
              reportPath={["index", "index"]}
              exact
            />
            <PrivateRoute
              // Enterprise overview
              path={`/enterprise/:enterprise_id`}
              component={Overview}
              user={this.props.user}
              exact
            />

            <PrivateRoute
              // Enterprise setup
              path={`/enterprise/:enterprise_id/setup`}
              component={EnterpriseSetup}
              user={this.props.user}
            />
            <PrivateRoute
              // User manager
              path={`/enterprise/:enterprise_id/:group_id/user-management`}
              component={UserManagement}
              user={this.props.user}
            />
            <PrivateRoute
              // User manager
              path={`/enterprise/:enterprise_id/:group_id/group-management`}
              component={GroupManagement}
              user={this.props.user}
            />
            <PrivateRoute
              // Enterprise manager
              path={`/enterprise/:enterprise_id/:group_id/enterprise-manager`}
              component={EnterpriseManager}
              user={this.props.user}
              disableBreadcrumbs={true}
            />

            <PrivateRoute
              // Enterprise manager -> view group
              path={`/enterprise/:enterprise_id/:group_id/view-group`}
              root_group={this.props.root_group}
              component={ViewGroup}
              user={this.props.user}
              activeStyle={{
                fontWeight: "bold",
                color: "red",
              }}
              disableBreadcrumbs={true}
            />
            <PrivateRoute
              // Enterprise manager -> view group -> Add site from root
              path={`/enterprise/:enterprise_id/:group_id/add-sites-from-root`}
              component={AddFromRoot}
              user={this.props.user}
              activeStyle={{
                fontWeight: "bold",
                color: "red",
              }}
              disableBreadcrumbs={true}
            />
            <PrivateRoute
              // Enterprise manager -> create sites
              path={`/enterprise/:enterprise_id/create-sites`}
              component={CreateSites}
              user={this.props.user}
              activeStyle={{
                fontWeight: "bold",
                color: "red",
              }}
              disableBreadcrumbs={true}
            />
            <PrivateRoute
              // Notifications manager
              path={`/enterprise/:enterprise_id/notifications`}
              component={Notifications}
              user={this.props.user}
            />
            <PrivateRoute
              // SOP Completed Action Report
              path={`/enterprise/:enterprise_id/:group_id/alarms/completed-report`}
              component={CompletedActionReport}
              user={this.props.user}
              // disableBreadcrumbs={true}
            />
            <PrivateRoute
              // Alarm Response //alarm-response
              path={`/enterprise/:enterprise_id/:group_id/alarms`}
              component={AlarmResponse}
              user={this.props.user}
              // disableBreadcrumbs={true}
            />
            <PrivateRoute
              path={`/enterprise/:enterprise_id/:group_id/custom-reports`}
              component={CustomReports}
              user={this.props.user}
              // disableBreadcrumbs={true}
            />

            <PrivateRoute
              // Group overview
              path={`/enterprise/:enterprise_id/:group_id/`}
              component={Overview}
              user={this.props.user}
              exact
            />
            <PrivateRoute
              // Site overview sidepane
              path={`/enterprise/:enterprise_id/:group_id/:site_id`}
              component={Overview}
              user={this.props.user}
              exact
            />
            <PrivateRoute
              // Timeline view sidepane - child page
              path={`/enterprise/:enterprise_id/:group_id/:site_id/:bundle_id`}
              component={Overview}
              user={this.props.user}
              exact
            />

            <PrivateRoute
              // Bundle View (Outstanding)
              path={`/enterprise/:enterprise_id/:group_id/reports/:reportParent/:reportType/:bundle_id`}
              component={OutstandingBundleReportPage}
              user={this.props.user}
            />
            <PrivateRoute
              path={`/enterprise/:enterprise_id/:group_id/reports/:reportParent/:reportType`}
              component={ReportPage}
              user={this.props.user}
            />
            <PrivateRoute
              path={`/enterprise/:enterprise_id/:group_id/reports/:reportParent`}
              component={ReportPage}
              user={this.props.user}
            />
            {/* <PrivateRoute
              // Facewatch
              path={`/enterprise/:enterprise_id/:group_id/reports/facewatch`}
              component={FacewatchOverview}
              user={this.props.user}
              exact
            /> */}
            {/* <PrivateRoute
            // Outstanding
            path={`/enterprise/:enterprise_id/:group_id/reports/:reportType`}
            component={OutstandingReportPage}
            user={this.props.user}
          /> */}

            <PrivateRoute
              // User profile
              path={`/user/profile`}
              component={Profile}
              user={this.props.user}
              exact
            />
            <PrivateRoute
              // User settings
              path={`/user/settings`}
              component={Settings}
              user={this.props.user}
              exact
            />

            {/* OAUTH */}
            <PrivateRoute
              // User settings
              path={`/oauth/tether/:code`}
              component={ConnectingTether}
              user={this.props.user}
              disableBreadcrumbs={true}
              exact
            />

            {/* OAUTH */}
            <PrivateRoute
              // User settings
              path={`/oauth/brivo/:code`}
              component={ConnectingBrivo}
              user={this.props.user}
              disableBreadcrumbs={true}
              exact
            />

            <PrivateRoute
              path="/logout"
              component={Logout}
              user={this.props.user}
            />
            {/* <Route path="/public" component={PublicPage} /> */}

            {/* Private redirects */}
            <PrivateRoute
              path="/"
              component={() => {
                const loc = window.location.toString();

                let to = "/enterprise";
                // Oauth tether re-routing
                if (loc.includes("oauth/tether?code=")) {
                  to = "/oauth/tether/" + loc.split("?code=")[1];
                }

                // Oauth brivo re-routing
                if (loc.includes("oauth/brivo?code=")) {
                  to = "/oauth/brivo/" + loc.split("?code=")[1];
                }

                return <Redirect to={to} />;
              }}
              user={this.props.user}
            />

            {/* Public Redirects */}
            <Route
              // Email link hash re-routing
              path="/"
              exact
              component={() => {
                const loc = window.location.toString();
                console.log("location", loc);

                let to = "/enterprise";
                // Accept Invite - email re-routing
                // from:  /#/accept-invite?U=token
                // to:    /accept-invite/token
                if (loc.includes("accept-invite?U=")) {
                  to = "/accept-invite/" + loc.split("?U=")[1];
                }
                // Reset Password - email re-routing
                // from:  /#/login?Token=token
                // to:    /reset-password/token
                else if (loc.includes("login?Token=")) {
                  to = "/reset-password/" + loc.split("?Token=")[1];
                }
                // Oauth tether re-routing
                else if (loc.includes("oauth/tether?code=")) {
                  to = "/oauth/tether/" + loc.split("?code=")[1];
                }
                // Oauth brivo re-routing
                else if (loc.includes("oauth/brivo?code=")) {
                  to = "/oauth/brivo/" + loc.split("?code=")[1];
                }

                return <Redirect to={to} />;
              }}
            />
            {/* 404 */}
            <Route component={PageNotFound} />
          </Switch>
        </Router>
      </>
    );
  }
}

// Disable hot reloading for production
App = process.env.NODE_ENV === "production" ? App : hot(App);

const mapStateToProps = (state) => {
  return { user: state.user, browser: state.browser };
};
export default connect(mapStateToProps)(App);
