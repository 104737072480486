import React from "react";

class AppStore extends React.Component {
  componentDidMount() {
    window.location = "https://launchpad.conxtd.com";
  }
  render() {
    return null;
  }
}
export default AppStore;
