import React from "react";

import DAYS_AGO_COLORS from "constants/DAYS_AGO_COLORS.json";
import COLOUR_CODES from "constants/COLOUR_CODES.json";
import PEOPLE_COUNT_FILTER_COLORS from "constants/PEOPLE_COUNT_FILTER_COLORS.json";

import { default as T } from "services/i18n/Translate";

export default {
  open: {
    overview: {
      index: 0,
    },

    // TODO: translate
    title: "Open",
    description: "Show whether sites opened on time compared to schedule.",

    /**
     * A live refresh object, describing a window for when to refresh
     * more frequently (short interval). Defaults to null (no refreshing)
     *
     * @property windowStart int 0-23, the hour of which to start short
     * interval refreshing. E.g. 4 starts at 4AM
     *
     * @property windowEnd int 0-23, same as windowStart but determines
     * the end of the window
     *
     * @property shortInterval int time in ms: the length of the "short
     * interval". E.g 5000 would mean a 5 second refresh between
     * the times of windowStart and windowEnd
     *
     * @property longInterval int time in ms: the same as shortInterval
     * but outside of the window
     */
    liveRefresh: {
      // Start at 4AM
      windowStart: 4,
      // End at 11AM
      windowEnd: 11,
      // 1 minute
      // shortInterval: 1000 * 10,
      shortInterval: 1000 * 60,
      // 15 minutes
      // longInterval: 1000 * 10 * 1,
      longInterval: 1000 * 60 * 15,
    },

    dataTable: {
      show: true,
    },

    charts: {
      barStack: {
        show: true,
      },
      pie: {
        filter: {
          type: "eventType",
          // TODO: remove/translate
          keys: ["Early", "On Time", "Late", "Failed", "Abnormal", "Pending"],
          colors: [
            COLOUR_CODES["alertOrange"],
            COLOUR_CODES["restoreGreen"],
            COLOUR_CODES["failRed"],
            COLOUR_CODES["testGrey"],
            COLOUR_CODES["infoPurple"],
            COLOUR_CODES["testGrey"],
          ],
        },
      },
    },
  },
  close: {
    overview: {
      index: 1,
    },

    dayPicker: {
      // Related to below, yesterday's liveClose
      //  data ends at 6AM today
      yesterdayEndsAt: "12:00",
      // Disable clicking on today
      //  until report has been generated
      //  wait till midday to enable "today's" data
      disableTodayFrom: "00:00",
      disableTodayUntil: "12:00",
    },

    // TODO: translate
    title: "Close",
    description: "Show whether sites closed on time compared to schedule.",
    liveRefresh: {
      // Start at 3PM (15:00)
      windowStart: 15,
      // End at 2AM
      windowEnd: 2,
      // 1 minute
      // shortInterval: 1000 * 10,
      shortInterval: 1000 * 60,
      // 15 minutes
      // longInterval: 1000 * 10 * 1,
      longInterval: 1000 * 60 * 15,
    },

    dataTable: {
      show: true,
    },

    charts: {
      barStack: {
        show: true,
      },
      pie: {
        filter: {
          type: "eventType",
          // TODO: remove/translate
          keys: ["Early", "On Time", "Late", "Pending", "Abnormal"],
          colors: [
            COLOUR_CODES["alertOrange"],
            COLOUR_CODES["restoreGreen"],
            COLOUR_CODES["failRed"],
            COLOUR_CODES["testGrey"],
            COLOUR_CODES["infoPurple"],
          ],
        },
      },
    },
  },
  fire: {
    noDataAvailableRenderer: () => (
      <div className="row no-data-available" style={{ width: "100%" }}>
        <div className="col-sm-12 text-center">
          <h6>
            <span>
              <T>No events have been recorded for the selected day.</T>
            </span>
          </h6>
        </div>
      </div>
    ),
    overview: {
      index: 2,
      noDataAvailableRenderer: () => (
        <div className="row pie-row no-data-available pb-1">
          <div className="col-sm-12 text-center">
            <h6 className="px-3">
              <span>
                <T>No schedules in place.</T>
              </span>
              <span className="widget-small-hide">
                <hr />
                <T>
                  Add your Fire Testing schedule and this report will determine
                  whether tests have been completed on time.
                </T>
                <br />
                <br />
                <T>Talk to CONXTD Professional Services</T>
              </span>
            </h6>
          </div>
        </div>
      ),
    },

    // TODO: translate
    title: "Fire Test",
    description: "Fire alarm test statistics.",
    liveRefresh: {
      // Start at 6AM (15:00)
      windowStart: 6,
      // End at 6PM
      windowEnd: 18,
      // 5 minutes
      // shortInterval: 1000 * 10,
      shortInterval: 1000 * 60 * 5,
      // 15 minutes
      // longInterval: 1000 * 10 * 1,
      longInterval: 1000 * 60 * 15,
    },

    dataTable: {
      show: true,
    },

    charts: {
      barStack: {
        show: true,
      },
      pie: {
        filter: {
          type: "eventType",
          // TODO: remove/translate
          keys: ["Early", "On Time", "Late", "Pending", "Abnormal"],
          colors: [
            COLOUR_CODES["alertOrange"],
            COLOUR_CODES["restoreGreen"],
            COLOUR_CODES["failRed"],
            COLOUR_CODES["testGrey"],
            COLOUR_CODES["infoPurple"],
          ],
        },
      },
    },
  },
  // panic
  panic: {
    noDataAvailableRenderer: () => (
      <div className="row no-data-available" style={{ width: "100%" }}>
        <div className="col-sm-12 text-center">
          <h6>
            <span>
              <T>No events have been recorded for the selected day.</T>
            </span>
          </h6>
        </div>
      </div>
    ),
    overview: {
      index: 2,
      noDataAvailableRenderer: () => (
        <div className="row pie-row no-data-available pb-1">
          <div className="col-sm-12 text-center">
            <h6 className="px-3">
              <span>
                <T>No schedules in place.</T>
              </span>
              <span className="widget-small-hide">
                <hr />
                <T>
                  Add your Panic Testing schedule and this report will determine
                  whether tests have been completed on time.
                </T>
                <br />
                <br />
                <T>Talk to CONXTD Professional Services</T>
              </span>
            </h6>
          </div>
        </div>
      ),
    },

    // TODO: translate
    title: "Panic Test",
    description: "Panic alarm test statistics.",
    liveRefresh: {
      // Start at 6AM (15:00)
      windowStart: 6,
      // End at 6PM
      windowEnd: 18,
      // 5 minutes
      // shortInterval: 1000 * 10,
      shortInterval: 1000 * 60 * 5,
      // 15 minutes
      // longInterval: 1000 * 10 * 1,
      longInterval: 1000 * 60 * 15,
    },

    dataTable: {
      show: true,
    },

    charts: {
      barStack: {
        show: true,
      },
      pie: {
        filter: {
          type: "eventType",
          // TODO: remove/translate
          keys: ["Early", "On Time", "Late", "Pending", "Abnormal"],
          colors: [
            COLOUR_CODES["alertOrange"],
            COLOUR_CODES["restoreGreen"],
            COLOUR_CODES["failRed"],
            COLOUR_CODES["testGrey"],
            COLOUR_CODES["infoPurple"],
          ],
        },
      },
    },
  },
  overnight: {
    noDataAvailableRenderer: () => (
      <div className="row no-data-available" style={{ width: "100%" }}>
        <div className="col-sm-12 text-center">
          <h6>
            <span>
              <T>No events have been recorded for the selected day or time range.</T>
            </span>
          </h6>
        </div>
      </div>
    ),
    overview: {
      index: 3,
      noDataAvailableRenderer: () => (
        <div className="row no-data-available mx-0" style={{ width: "100%" }}>
          <div className="col-sm-12 text-center">
            <h6>
              <span>
                <T>No overnight activity today</T> <hr />{" "}
                <T>Click to access previous days reports.</T>
              </span>
            </h6>
          </div>
        </div>
      ),
      charts: {
        pie: {
          renderer: (props) => {
            const { filterComponent, pie } = props;
  
            return (
              <div className="row pb-2 pie-row w-xl-110 larger-pie">
                <div className="col-xl-6 px-0 my-auto">{pie}</div>
                <div className="col-xl-6 pt-3 pl-4 pr-4 pt-xl-0 pl-xl-0 widget-filter">
                  {filterComponent}
                </div>
              </div>
            );
          },
        }
      }
    },

    title: "Overnight Activity",
    description: "Overnight activity statistics.",
    liveRefresh: {
      // Start at 11PM (23:00)
      windowStart: 23,
      // End at 3AM
      windowEnd: 3,
      // 1 minute
      // shortInterval: 1000 * 10,
      shortInterval: 1000 * 60 * 5,
      // 15 minutes
      // longInterval: 1000 * 10 * 1,
      longInterval: null,
    },

    timeRangeSelection: true,

    dataTable: {
      show: true,
      clickableEventFilters: false,
      // List of event ids
      suggestedFilters: [10, 5, 7, 169, 3],
    },

    charts: {
      barStack: {
        show: false,
      },
      pie: {
        hideLegend: true,
        filter: {
          component: "legend",
          type: "eventCount",
          // TODO: translate
          keys: [
            "Sixteen events or more",
            "Eleven to fifteen events",
            "Six to ten events",
            "Two to five events",
            "One event",
          ],
          colors: [...Object.values(DAYS_AGO_COLORS).reverse()],
        },
        renderer: (props) => {
          const { filterComponent, pie } = props;

          return (
            <div className="row pb-2 pie-row w-xl-110 larger-pie">
              <div className="col-xl-7 px-0 my-auto">{pie}</div>
              <div className="col-xl-5 pt-3 pt-xl-0 pl-xl-0">
                {filterComponent}
              </div>
            </div>
          );
        },
      },
    },

    firstSection: {
      renderer: (props) => {
        const { pie, progressBar, dayPicker } = props;
        return (
          <div className="row">
            <div className="col-xl-4 pb-4 pr-xl-0 text-center">
              {dayPicker}

              {progressBar}
            </div>
            <div className="col-xl-8 pb-4 pl-xl-0 visual-data">{pie}</div>
          </div>
        );
      },
    },

    // overview: {
    //   charts: {
    //     pie: {
    //       // Whether to show the pie chart legend
    //       hideLegend: true
    //     }
    //   }
    // }
  },
  suspicious: {
    title: "Suspicious Activity",
    description: "Suspicious activity statistics.",
    dataTable: {
      show: true,
    },
    charts: {
      barStack: {
        show: false,
      },
      pie: {
        hideLegend: true,
        filter: {
          component: "legend",
          type: "eventType",
          // TODO: translate
          keys: ["Suspicious activity", "Acceptable activity"],
          colors: ["#962D23", "#E67F22"],
        },
        renderer: (props) => {
          const { filterComponent, pie } = props;

          return (
            <>
              <div className="row pb-2 pie-row w-xl-110 larger-pie">
                <div className="col-xl-7 px-0 my-auto">{pie}</div>
                <div className="col-xl-5 pt-3 pt-xl-0 pl-xl-0">
                  {filterComponent}
                </div>
              </div>
            </>
          );
        },
      },
    },
    noDataAvailableRenderer: () => (
      <div className="row no-data-available" style={{ width: "100%" }}>
        <div className="col-sm-12 text-center">
          <h6>
            <span>
              <T>No events have been recorded for the selected day.</T>
            </span>
          </h6>
        </div>
      </div>
    ),
    overview: {
      index: 3,
      noDataAvailableRenderer: () => (
        <div className="row mx-0 no-data-available" style={{ width: "100%" }}>
          <div className="col-sm-12 text-center">
            <h6>
              <span>
                <T>No suspicious activity today</T> <hr />{" "}
                <T>Click to access previous days reports.</T>
              </span>
            </h6>
          </div>
        </div>
      ),
      charts: {
        pie: {
          renderer: (props) => {
            const { filterComponent, pie } = props;
  
            return (
              <div className="row pb-2 pie-row w-xl-110 larger-pie">
                <div className="col-xl-6 px-0 my-auto">{pie}</div>
                <div className="col-xl-6 pt-3 pl-4 pr-4 pt-xl-0 pl-xl-0 widget-filter d-flex align-items-center">
                  <div className="flex-grow-1">{filterComponent}</div>
                </div>
              </div>
            );
          },
        }
      }
    },
  },
  // people count
  peoplecount: {
    noDataAvailableRenderer: () => (
      <div className="row no-data-available" style={{ width: "100%" }}>
        <div className="col-sm-12 text-center">
          <h6>
            <span>
              <T>No events have been recorded for the selected day.</T>
            </span>
          </h6>
        </div>
      </div>
    ),
    overview: {
      index: 3,
      noDataAvailableRenderer: () => (
        <div className="row no-data-available mx-0" style={{ width: "100%" }}>
          <div className="col-sm-12 text-center">
            <h6>
              <span>
                <T>No people counting activity today</T> <hr />{" "}
                <T>Click to access previous days reports.</T>
              </span>
            </h6>
          </div>
        </div>
      ),
    },

    title: "People Counting Activity",
    description: "People counting activity statistics.",
    liveRefresh: {
      // Start at 6AM (15:00)
      windowStart: 6,
      // End at 6PM
      windowEnd: 18,
      // 5 minutes
      // shortInterval: 1000 * 10,
      shortInterval: 1000 * 60 * 5,
      // 15 minutes
      // longInterval: 1000 * 10 * 1,
      longInterval: 1000 * 60 * 15,
    },

    dataTable: {
      show: true,
      clickableEventFilters: false,
      // List of event ids
      suggestedFilters: [10, 5, 7, 169, 3],
    },

    charts: {
      barStack: {
        show: false,
      },
      pie: {
        hideLegend: true,
        filter: {
          component: "legend",
          type: "eventCount",
          // TODO: translate
          keys: [
            "201 or more",
            "151 to 200",
            "101 to 150",
            "51 to 100",
            "Up to 50",
          ],
          colors: [...Object.values(PEOPLE_COUNT_FILTER_COLORS).reverse()],
        },
        renderer: (props) => {
          const { filterComponent, pie } = props;

          return (
            <div className="row pb-2 pie-row w-xl-110 larger-pie">
              <div className="col-xl-7 px-0 my-auto">{pie}</div>
              <div className="col-xl-5 pt-3 pt-xl-0 pl-xl-0">
                {filterComponent}
              </div>
            </div>
          );
        },
      },
    },

    firstSection: {
      renderer: (props) => {
        const { pie, progressBar, dayPicker } = props;
        return (
          <div className="row">
            <div className="col-xl-4 pb-4 pr-xl-0 text-center">
              {dayPicker}

              {progressBar}
            </div>
            <div className="col-xl-8 pb-4 pl-xl-0 visual-data">{pie}</div>
          </div>
        );
      },
    },
  },
  // 08/21 people count hourly
  peoplecounthourly: {
    noDataAvailableRenderer: () => (
      <div className="row no-data-available" style={{ width: "100%" }}>
        <div className="col-sm-12 text-center">
          <h6>
            <span>
              <T>No events have been recorded for the selected day.</T>
            </span>
          </h6>
        </div>
      </div>
    ),
    overview: {
      index: 3,
      noDataAvailableRenderer: () => (
        <div className="row no-data-available mx-0" style={{ width: "100%" }}>
          <div className="col-sm-12 text-center">
            <h6>
              <span>
                <T>No people counting activity today</T> <hr />{" "}
                <T>Click to access previous days reports.</T>
              </span>
            </h6>
          </div>
        </div>
      ),
    },

    title: "People Counting Hourly",
    description: "People counting hourly activity statistics.",
    liveRefresh: {
      // Start at 6AM (15:00)
      windowStart: 6,
      // End at 6PM
      windowEnd: 18,
      // 5 minutes
      // shortInterval: 1000 * 10,
      shortInterval: 1000 * 60 * 5,
      // 15 minutes
      // longInterval: 1000 * 10 * 1,
      longInterval: 1000 * 60 * 15,
    },

    dataTable: {
      show: true,
      clickableEventFilters: false,
      // List of event ids
      suggestedFilters: [],
    },

    chartType: "hourly",

    charts: { 
      barStack: {
        show: false,
      },
      hourly: {
        hideLegend: false,
        renderer: (props) => {
          const { hourly } = props;

          return (
            <div className="row pie-row w-xl-110 hourly-mixed">
              <div className="col-xl-12 px-0 my-auto">{hourly}</div>
            </div>
          );
        },
      }
    },

    firstSection: {
      renderer: (props) => {
        const { hourly, progressBar, dayPicker } = props;
        return (
          <div className="row">
            <div className="col-xl-4 pb-4 pr-xl-0 text-center">
              {dayPicker}

              {progressBar}
            </div>
            <div className="col-xl-8 pb-4 pl-xl-0 visual-data">{hourly}</div>
          </div>
        );
      },
    },
  },
};
