const initialState = {
  data: [],
  bundles: [],
  loadingCustomReport: null,
};

export default function customReports(state = initialState, action) {
  switch (action.type) {

    case "LOAD_CUSTOM_REPORT_START":
      return {
        ...state,
        loadingCustomReport: true,
      };

    case "LOAD_CUSTOM_REPORT_SUCCESS":
      return {
        ...state,
        data: action.data ? action.data : [],
        loadingCustomReport: false,
      }

    case "LOAD_CUSTOM_REPORT_FAILED":
      return {
        ...state,
        loadingCustomReport: false,
      };

    case "LOAD_CUSTOM_REPORT_CANCEL":
      return {
        ...state,
        loadingCustomReport: false,
      };

    case "CLEAR_CUSTOM_REPORT":
      return {
        ...state,
        data: [],
        loadingCustomReport: false,
      };

    case "LOAD_CUSTOM_REPORT_BUNDLES_START":
      return {
        ...state,
      };

    case "LOAD_CUSTOM_REPORT_BUNDLES_SUCCESS":
      return {
        ...state,
        bundles: action.data,
      }

    case "LOAD_CUSTOM_REPORT_BUNDLES_FAILED":
      return {
        ...state,
      };

    case "LOAD_CUSTOM_REPORT_BUNDLES_CANCEL":
      return {
        ...state,
      };

    default:
      return state;
  }
}