import get from "lodash/get";

import EventFilter from "components/Enterprise/Reports/Types/Scheduled/EventFilter";

export default {
  EXPECTED: { show: false },
  ACTUAL: {
    Header: "Recorded Time"
  },
  DIFFERENCE: { show: false },
  RESULT: {
    width: 160,
    Header: "Event",
    headerClassName: "event-type",
    Filter: EventFilter,
    filterType: "exact"
  },
  EVENT_TEXT: {
    width: 160
  },
  buildExportArray: ({
    data,
    getEventMeta,
    // getActualFromOccurrence
    // getDiffFromOccurrence
    getActualWithTimezone,
  }) => {
    let result = [];

    let headers = [
      "Custom ID",
      "Site Name",
      "Group",
      "Site Ref",
      "Occurrences",

      "Event",
      // "Expected Time",
      "Recorded Time",
      // "Difference",
      "Event Text",
      "Occurrence"
    ];

    // Add headers
    result.push(headers);

    data.forEach(row => {
      const occurrence = get(row, "result.0");
      const event = getEventMeta(occurrence);

      // Push site data first
      result.push([
        row["custom-id"] || "",
        row["site-name"],
        row.group || "",
        row["site-ref"] || "",
        row.occurrences,

        event.label || "",
        // row["expected-time"] || "",
        // getActualFromOccurrence(get(row["actual-time"], 0)),
        getActualWithTimezone(get(row["actual-time"], 0)),
        // getDiffFromOccurrence(get(row["difference"], 0)),
        row["event-text"]
      ]);

      // And show occurrences below
      // const occurrences = get(row, "result");
      // occurrences.forEach((occurrence, index) => {
      //   const event = getEventMeta(occurrence);

      //   result.push([
      //     "",
      //     "",
      //     "",
      //     "",
      //     "",

      //     event.label || "",
      //     // row["expected-time"] || "",
      //     getActualFromOccurrence(get(row["actual-time"], 0)),
      //     // getDiffFromOccurrence(get(row["difference"], 0)),
      //     row["event-text"],
      //     "#" + (index + 1)
      //   ]);
      // });
    });

    return result;
  }
};
