import _ from "lodash";

export default (state = {}, action) => {
  if (
    !action.hasOwnProperty("recordEvent") //||
    // Record only on pro.conxtd.com
    // window.location.hostname !== "pro.conxtd.com"
  ) {
    return state;
  }

  console.log("Recorded action");
  // Pick only the selected properties
  const meta = _.pick(action, action.recordEvent.propertiesToRecord);
  window.Intercom("trackEvent", action.type, meta);

  return state;
};
