export const setEmSelectedTab = (params) => {
  return { type: "SET_EM_SELECTED_TAB", ...params };
};
export const setEmIntegrationTabContent = (params) => {
  return { type: "SET_EM_INTEGRATION_TAB_CONTENT", ...params };
};
export const setEmPriorityEventsManagementTabContent = (params) => {
  return { type: "SET_EM_PRIORITY_EVENTS_MANAGEMENT_TAB_CONTENT", ...params };
};

/************** get calls **************/
/* Gets a list of all the enterprise site ids in the requested group
 */
export const loadEmSitesListStart = (params) => {
  return { type: "LOAD_EM_SITES_LIST_START", ...params };
};
export const loadEmSitesListSuccess = (params) => {
  return { type: "LOAD_EM_SITES_LIST_SUCCESS", ...params };
};
export const loadEmSitesListRestore = (response) => {
  return { type: "LOAD_EM_SITES_LIST_RESTORE", ...response };
};
export const loadEmSitesListFailed = (params) => {
  return { type: "LOAD_EM_SITES_LIST_FAILED", ...params };
};
export const loadEmSitesListCancel = (params) => {
  return { type: "LOAD_EM_SITES_LIST_CANCEL", ...params };
};
/* Gets a list of all groups for a enterprise
 */
export const getGroupsDropDownListStart = (params) => {
  return { type: "GET_GROUPS_DROPDOWN_LIST_START", ...params };
};
export const getGroupsDropDownListSuccess = (params) => {
  return { type: "GET_GROUPS_DROPDOWN_LIST_SUCCESS", ...params };
};
export const getGroupsDropDownListRestore = (response) => {
  return { type: "GET_GROUPS_DROPDOWN_LIST_RESTORE", ...response };
};
export const getGroupsDropDownListFailed = (params) => {
  return { type: "GET_GROUPS_DROPDOWN_LIST_FAILED", ...params };
};
export const getGroupsDropDownListCancel = (params) => {
  return { type: "GET_GROUPS_DROPDOWN_LIST_CANCEL", ...params };
};

/* Parse a enterprise group id, responds with a site and report count */
export const getCountForMainPageStart = (params) => {
  return { type: "GET_COUNT_FOR_MAIN_PAGE_START", ...params };
};

export const getCountForMainPageSuccess = (params) => {
  return { type: "GET_COUNT_FOR_MAIN_PAGE_SUCCESS", ...params };
};

export const getCountForMainPageFailed = (params) => {
  return { type: "GET_COUNT_FOR_MAIN_PAGE_FAILED", ...params };
};
export const getCountForMainPageCancel = (params) => {
  return { type: "GET_COUNT_FOR_MAIN_PAGE_CANCEL", ...params };
};
export const getCountForMainPageRestore = (response) => {
  return { type: "GET_COUNT_FOR_MAIN_PAGE_RESTORE", ...response };
};
/* Return details of a site */
export const getSiteDetailStart = (params) => {
  return { type: "GET_SITE_DETAIL_START", ...params };
};

export const getSiteDetailSuccess = (params) => {
  return { type: "GET_SITE_DETAIL_SUCCESS", ...params };
};

export const getSiteDetailFailed = (params) => {
  return { type: "GET_SITE_DETAIL_FAILED", ...params };
};
export const getSiteDetailCancel = (params) => {
  return { type: "GET_SITE_DETAIL_CANCEL", ...params };
};
export const getSiteDetailRestore = (response) => {
  return { type: "GET_SITE_DETAIL_RESTORE", ...response };
};

/* Get group structure */
export const getGroupStructureStart = (params) => {
  return { type: "GET_GROUP_STRUCTURE_START", ...params };
};

export const getGroupStructureSuccess = (params) => {
  return {
    type: "GET_GROUP_STRUCTURE_SUCCESS",
    ...params,
  };
};

export const getGroupStructureFailed = (params) => {
  return { type: "GET_GROUP_STRUCTURE_FAILED", ...params };
};
export const getGroupStructureCancel = (params) => {
  return { type: "GET_GROUP_STRUCTURE_CANCEL", ...params };
};
export const getGroupStructureRestore = (response) => {
  return { type: "GET_GROUP_STRUCTURE_RESTORE", ...response };
};

/* Get user actions */
export const getUserActionsStart = (params) => {
  return { type: "GET_USER_ACTIONS_START", ...params };
};
export const getUserActionsSuccess = (params) => {
  return { type: "GET_USER_ACTIONS_SUCCESS", ...params };
};
export const getUserActionsFailed = (params) => {
  return { type: "GET_USER_ACTIONS_FAILED", ...params };
};
export const getUserActionsCancel = (params) => {
  return { type: "GET_USER_ACTIONS_CANCEL", ...params };
};

/* Get integrations */
export const getIntegrationsStart = (params) => {
  return { type: "GET_INTEGRATIONS_START", ...params };
};
export const getIntegrationsSuccess = (params) => {
  return { type: "GET_INTEGRATIONS_SUCCESS", ...params };
};
export const getIntegrationsFailed = (params) => {
  return { type: "GET_INTEGRATIONS_FAILED", ...params };
};
export const getIntegrationsCancel = (params) => {
  return { type: "GET_INTEGRATIONS_CANCEL", ...params };
};

/* Integration connect */
export const requestIntegrationConnectStart = params => {
  return { type: "REQUEST_INTEGRATION_CONNECT_START", ...params };
};
export const requestIntegrationConnectFailed = params => {
  return { type: "REQUEST_INTEGRATION_CONNECT_FAILED", ...params };
};
export const requestIntegrationConnectSuccess = params => {
  return { type: "REQUEST_INTEGRATION_CONNECT_SUCCESS", ...params };
};
export const setIntegrationConnectStatus = params => {
  return { type: "SET_INTEGRATION_CONNECT_STATUS", ...params };
};

/* Brivo integration */
export const getBrivoSitesStart = params => {
  return { type: "GET_BRIVO_SITES_START", ...params };
};
export const getBrivoSitesFailed = params => {
  return { type: "GET_BRIVO_SITES_FAILED", ...params };
};
export const getBrivoSitesSuccess = params => {
  return { type: "GET_BRIVO_SITES_SUCCESS", ...params };
};
export const getBrivoSitesCancel = params => {
  return { type: "GET_BRIVO_SITES_CANCEL", ...params };
};

export const getBrivoLinkedSitesStart = params => {
  return { type: "GET_BRIVO_LINKED_SITES_START", ...params };
};
export const getBrivoLinkedSitesFailed = params => {
  return { type: "GET_BRIVO_LINKED_SITES_FAILED", ...params };
};
export const getBrivoLinkedSitesSuccess = params => {
  return { type: "GET_BRIVO_LINKED_SITES_SUCCESS", ...params };
};
export const getBrivoLinkedSitesCancel = params => {
  return { type: "GET_BRIVO_LINKED_SITES_CANCEL", ...params };
};

/* Priority Events management */
export const getEventPairListStart = params => {
  return { type: "GET_EVENT_PAIR_LIST_START", ...params };
};
export const getEventPairListFailed = params => {
  return { type: "GET_EVENT_PAIR_LIST_FAILED", ...params };
};
export const getEventPairListSuccess = params => {
  return { type: "GET_EVENT_PAIR_LIST_SUCCESS", ...params };
};
export const getEventPairListCancel = params => {
  return { type: "GET_EVENT_PAIR_LIST_CANCEL", ...params };
};

export const getEventPairMapsStart = params => {
  return { type: "GET_EVENT_PAIR_MAPS_START", ...params };
};
export const getEventPairMapsFailed = params => {
  return { type: "GET_EVENT_PAIR_MAPS_FAILED", ...params };
};
export const getEventPairMapsSuccess = params => {
  return { type: "GET_EVENT_PAIR_MAPS_SUCCESS", ...params };
};
export const getEventPairMapsCancel = params => {
  return { type: "GET_EVENT_PAIR_MAPS_CANCEL", ...params };
};

export const setCurrentEventPairMap = params => {
  return { type: "SET_CURRENT_EVENT_PAIR_MAP", ...params };
};

/* Pin Maps */
export const loadPinMapsStart = (params) => {
  return { type: "LOAD_PIN_MAPS_START", ...params };
};

export const loadPinMapsSuccess = (params) => {
  return { type: "LOAD_PIN_MAPS_SUCCESS", ...params, };
};

export const loadPinMapsFailed = (params) => {
  return { type: "LOAD_PIN_MAPS_FAILED", ...params };
};

export const loadPinMapsCancel = (params) => {
  return { type: "LOAD_PIN_MAPS_CANCEL", ...params };
};

/************** post/delete calls **************/
/* Add group */
export const requestAddGroupStart = (params) => {
  return { type: "REQUEST_ADD_GROUP_START", ...params };
};

export const requestAddGroupSuccess = (params) => {
  return {
    type: "REQUEST_ADD_GROUP_SUCCESS",
    ...params,
  };
};
export const requestAddGroupFailed = (params) => {
  return { type: "REQUEST_ADD_GROUP_FAILED", ...params };
};
export const requestAddGroupCancel = (params) => {
  return { type: "REQUEST_ADD_GROUP_CANCEL", ...params };
};
