import React from "react";
import { connect } from "react-redux";

import Loading from "components/Loading";

import Alert from "components/Common/Alert";

import { withT } from "services/translation/";

import Icon from "components/Icons/Icon";

import RoleSelect from "components/Enterprise/UserManagement/Common/RoleSelect";
import Features from "components/Enterprise/UserManagement/Common/Features";

// import { Row, Col, Form, Button, Card } from "react-bootstrap";
import { Col, Form, Button, Card } from "react-bootstrap";

import { getGroupFromRoute } from "services/redux/selectors/groups";
import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";

import {
  requestUserManagementSearchReset,
  requestUserManagementInviteStart,
  requestUserManagementInviteNotified,
  loadUserManagementUserListStart
} from "services/redux/actions";

import { devMode } from "services/redux/selectors/";

/**
 * UserInvite/NewUser
 *
 * Form for inviting a new user to CONXTD, filling in user profile
 * details with the invite
 *
 */
class NewUser extends React.Component {
  state = {
    role: "",
    validated: false
  };
  inviteUser = e => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    this.setState({ validated: true });
    if (form.checkValidity() === false) {
      return;
    }

    const { enterprise, group } = this.props;
    const group_id = (group && group.id) || enterprise.root_group_id;

    const user = {};

    const { role } = this.state;

    document.querySelectorAll("#NewUser input").forEach(item => {
      user[item.name] = item.value;
    });

    const features = [];
    document
      .querySelectorAll("#NewUser .feature-checkbox input")
      .forEach(checkbox => checkbox.checked && features.push(checkbox.id));

    this.props.dispatch(
      requestUserManagementInviteStart({
        email: user.email,
        emailConfirm: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        profile: {
          position: user.job_title || "",
          company: { name: user.company || "" },
          // These are hard coded for now but will need to be an
          // autocomplete select or something at some point #196
          locale: "en",
          timezone: "Europe/London"
        },
        role_id: role,
        featureIds: features,
        existing: false,

        enterprise_id: enterprise.id,
        group_id
      })
    );
  };
  render() {
    const { users, group, enterprise, t } = this.props;
    const group_id = (group && group.id) || enterprise.root_group_id;
    const groupName = (group && group.name) || enterprise.name;

    const { loading, user, error, success } = users.actions.invite;

    const { validated } = this.state;

    if (user === null) return null;

    if (error.message) {
      Alert({
        text: t(`errors.create${devMode() ? "_dev" : ""}`, error.message),
        icon: "error",
        onAfterClose: () => {
          // Reset error message
          this.props.dispatch(requestUserManagementInviteNotified());
        }
      });
    }
    if (success) {
      // Reset error message
      this.props.dispatch(requestUserManagementInviteNotified());

      // Refresh user list
      this.props.dispatch(
        loadUserManagementUserListStart({
          enterprise_id: enterprise.id,
          group_id: group_id
        })
      );
      Alert({
        text: t("create_and_invite_success", user.email, groupName),
        icon: "success",
        onAfterClose: () => {
          // Reset user search
          this.props.dispatch(requestUserManagementSearchReset());
        }
      });
    }

    return (
      <>
        <p>
          <b>{t("x_is_not_on_conxtd", user.email)}</b>
        </p>
        <p>{t("invite_new_user_description", groupName)}</p>

        <Form
          onSubmit={this.inviteUser}
          id="NewUser"
          noValidate
          validated={validated}>
          <Card bg="light">
            <Card.Body>
              <Form.Row>
                <Form.Group
                  controlId="formGroupUserInvite"
                  as={Col}
                  xl="3"
                  sm="4">
                  <Form.Label>{t("email_address_input_label")}</Form.Label>

                  <Form.Text className="font-14">{user.email}</Form.Text>
                  <Form.Control type="hidden" name="email" value={user.email} />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group
                  required
                  controlId="formGroupUserFirstName"
                  as={Col}
                  xl="3"
                  sm="4">
                  <Form.Label>{t("first_name_input_label")}</Form.Label>
                  <Form.Control
                    required
                    name="first_name"
                    component="input"
                    type="text"
                  />
                  <Form.Control.Feedback type="invalid">
                    {t("first_name_validation_feedback")}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  required
                  controlId="formGroupUserLastName"
                  as={Col}
                  xl="3"
                  sm="4">
                  <Form.Label>{t("last_name_input_label")}</Form.Label>
                  <Form.Control
                    required
                    name="last_name"
                    component="input"
                    type="text"
                  />
                  <Form.Control.Feedback type="invalid">
                    {t("last_name_validation_feedback")}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  controlId="formGroupUserCompany"
                  as={Col}
                  xl="3"
                  sm="4">
                  <Form.Label>{t("company_input_label")}</Form.Label>
                  <Form.Control name="company" component="input" type="text" />
                </Form.Group>

                <Form.Group
                  controlId="formGroupUserJobTitle"
                  as={Col}
                  xl="3"
                  sm="4">
                  <Form.Label>{t("job_title_input_label")}</Form.Label>
                  <Form.Control
                    name="job_title"
                    component="input"
                    type="text"
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group
                  controlId="formGroupUserRole"
                  as={Col}
                  xl="3"
                  sm="4">
                  <Form.Label>{t("role_input_label")}</Form.Label>

                  <RoleSelect
                    onSelect={e => {
                      this.setState({ role: e.target.value });
                    }}
                  />
                </Form.Group>

                <Form.Group
                  controlId="formGroupFeatures"
                  as={Col}
                  xl="3"
                  sm="4">
                  <Form.Label className="text-muted">
                    {t("features_input_label")}
                  </Form.Label>

                  <Features />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group
                  className="text-right"
                  controlId="formGroupUserActions"
                  as={Col}
                  md="12">
                  <Button
                    variant="secondary"
                    onClick={() => {
                      this.props.dispatch(requestUserManagementSearchReset());
                    }}
                    // disabled={resetPassword.loading === true || !emailValid}
                    disabled={loading}>
                    {t("cancel_button")}
                  </Button>

                  <Button
                    variant="success"
                    type="submit"
                    className="ml-2"
                    disabled={loading}>
                    <Icon className="fas fa-user-plus pr-2" />

                    {t("button_invite_user")}
                    {loading && <Loading />}
                  </Button>
                </Form.Group>
              </Form.Row>
            </Card.Body>
          </Card>
        </Form>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    enterprise: getEnterpriseFromRoute(state, props),
    group: getGroupFromRoute(state, props),
    users: state.users
  };
};
export default withT(
  connect(mapStateToProps)(NewUser),
  "user_management.user_invite"
);
