import _ from "lodash";
import produce from "immer";

import merge from "deepmerge";

import REPORTS_CONFIG from "constants/Reports/config/";

const initialState = {
  default: {
    loading: null,
    config: REPORTS_CONFIG["default"]["default"],
    filter: {
      eventCount: {
        show: {},
      },
    },
  },
  bundle: {
    nextRefresh: null,
    lastRefresh: null,
    loading: null,
    data: {
      name: "",
      events: [],
    },
    filter: {
      eventCount: {
        show: {},
      },
    },
    eventPairAcknowledgement: {
      loading: null,
      sudo_state_id: null,
    },
    DataTable: {
      filtered: [],
    },
    config: merge(
      REPORTS_CONFIG["default"]["default"],
      REPORTS_CONFIG["outstanding"]["bundle"] || {}
    ),
  },
  index: {
    nextRefresh: null,
    lastRefresh: null,
    loading: null,
    data: {
      pair_bundles: [],
      sites_count: null,
    },
    config: merge(
      REPORTS_CONFIG["default"]["default"],
      REPORTS_CONFIG["outstanding"]["index"] || {}
    ),
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "LOAD_OUTSTANDING_REPORT_START":
      return produce(state, (draftState) => {
        //const outstanding = { ..._.omit(action, "type"), loading: true };

        _.set(draftState, "index.loading", true);
      });
    case "LOAD_OUTSTANDING_REPORT_SUCCESS":
      // No config provided by server yet
      const config = {};

      return {
        ...state,
        index: {
          data: action.index,
          config: merge(state.index.config, config),
          loading: false,
          lastRefresh: action.lastRefresh,
          nextRefresh: action.nextRefresh,
        },
      };
    case "LOAD_OUTSTANDING_REPORT_CANCEL":
      return produce(state, (draftState) => {
        // const bundle = { ..._.omit(action, "type"), loading: true };

        _.set(draftState, "index.loading", false);
      });

    case "LOAD_OUTSTANDING_REPORT_BUNDLE_START":
      // return { ...state, bundle: { ...state.bundle, loading: true } };
      return produce(state, (draftState) => {
        // const bundle = { ..._.omit(action, "type"), loading: true };

        _.set(draftState, "bundle.loading", true);
      });
    case "LOAD_OUTSTANDING_REPORT_BUNDLE_CANCEL":
      return produce(state, (draftState) => {
        // const bundle = { ..._.omit(action, "type"), loading: true };

        _.set(draftState, "bundle", initialState.bundle);
      });
    case "LOAD_OUTSTANDING_REPORT_BUNDLE_SUCCESS":
      // No config provided by server yet

      return {
        ...state,
        bundle: {
          ...state.bundle,
          data: action.bundle,
          loading: false,
          lastRefresh: action.lastRefresh,
          nextRefresh: action.nextRefresh,
          // We should merge when server provides config
          // config: state.bundle.config
        },
      };
    // return produce(state, draftState => {
    //   let bundle = {
    //     // Preserve the rest of the state
    //     ...state.bundle,
    //     ..._.omit(action, "type"),
    //     loading: false
    //   };
    //   _.set(draftState, action.key, { ...bundle });
    // });
    case "LOAD_FACEWATCH_START":
      return produce(state, (draftState) => {
        // const bundle = { ..._.omit(action, "type"), loading: true };

        _.set(draftState, "facewatch.loading", true);
      });
    case "LOAD_FACEWATCH_SUCCESS":
      return produce(state, (draftState) => {
        // const bundle = { ..._.omit(action, "type"), loading: true };

        _.set(draftState, "facewatch.loading", false);
        _.set(draftState, "facewatch.data", action.data);
      });

    case "CHANGE_OUTSTANDING_REPORT_FILTER":
      return produce(state, (draftState) => {
        const show = _.get(
          draftState.bundle.filter.timeInFail.show,
          action.show
        );

        // Get visible filters
        const visibleFilters = Object.keys(
          state.bundle.filter.timeInFail.show
        ).filter((f) => state.bundle.filter.timeInFail.show[f]).length;

        // Reset when all filters are true
        if (visibleFilters === 4) {
          draftState.bundle.filter.timeInFail.show = {};
          return;
        }

        // Toggle visibility
        if (show === true) {
          delete draftState.bundle.filter.timeInFail.show[action.show];
        } else {
          draftState.bundle.filter.timeInFail.show[action.show] = true;
        }
      });
    case "LOAD_REPORTS_OVERVIEW_FAILED":
      // Reset reports when overview fails
      return initialState;
    case "LOGOUT_REQUEST":
      // Reset to initial state when user logs out
      return initialState;
    case "OUTSTANDING_EVENT_PAIR_ACKNOWLEDEMENT_START":
      return produce(state, (draftState) => {
        _.set(draftState, "eventPairAcknowledgement.loading", true);
      });
    case "OUTSTANDING_EVENT_PAIR_ACKNOWLEDEMENT_SUCCESS":
      return {
        ...state,
        eventPairAcknowledgement: {
          sudo_state_id: action.data,
          loading: false,
        },
      };

    case "OUTSTANDING_EVENT_PAIR_ACKNOWLEDEMENT_FAILED":
      return initialState;

    default:
      return state;
  }
};
