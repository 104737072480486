import React from "react";
import { connect } from "react-redux";

import { Button, Modal, Form } from "react-bootstrap";

import Select from 'react-select';

import { getBrivoLinkedSitesStart } from "services/redux/actions/enterpriseManager";

import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";
import { getSites } from "services/redux/selectors";

import Alert from "components/Common/Alert";

import conxtdOut from "apis/conxtdOut";

import "./BrivoLinkSiteModal.scss";

import _ from "lodash";

class BrivoLinkSiteModal extends React.Component {
  state = {
    conxtdSiteOptions: [],
    selectedConxtdSiteOption: null,
    saving: false,
  }

  componentDidUpdate(prevProps) {
    const { show, conxtdSites, conxtdSite } = this.props;

    if ((!prevProps.show) && show) { // 'show' changing from false to true
      const conxtdSiteOptions = conxtdSites.map(conxtdSite => {
        let fullAddress = "";

        fullAddress = conxtdSite.name ? conxtdSite.name : "";
        fullAddress = `${fullAddress}${fullAddress && conxtdSite.company ? ", " : ""}${conxtdSite.company ? conxtdSite.company : ""}`;
        fullAddress = `${fullAddress}${fullAddress && conxtdSite.address1 ? ", " : ""}${conxtdSite.address1 ? conxtdSite.address1 : ""}`;
        fullAddress = `${fullAddress}${fullAddress && conxtdSite.address2 ? ", " : ""}${conxtdSite.address2 ? conxtdSite.address2 : ""}`;
        fullAddress = `${fullAddress}${fullAddress && conxtdSite.town ? ", " : ""}${conxtdSite.town ? conxtdSite.town : ""}`;
        fullAddress = `${fullAddress}${fullAddress && conxtdSite.postcode ? ", " : ""}${conxtdSite.postcode ? conxtdSite.postcode : ""}`;

        return {
          label: fullAddress,
          value: conxtdSite.sudo_site && conxtdSite.sudo_site.id,
        }
      });

      this.setState({
        conxtdSiteOptions,
        selectedConxtdSiteOption: _.find(conxtdSiteOptions, ['value', conxtdSite.id] || null),
      });
    }
  }

  render() {
    const { selectedBrivoSite, enterprise, conxtdSite } = this.props;
    const { conxtdSiteOptions, selectedConxtdSiteOption, saving } = this.state;

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-60w"
        centered
      >
        <Form onSubmit={(e) => {
          e.preventDefault();

          this.setState({ saving: true });

          const selectedConxtdSiteId = e.target.selected_conxtd_site.value;

          if (String(selectedConxtdSiteId) !== String(conxtdSite.id)) { // the selected site id is different to the one passed in on the props,
                                                        // i.e. the user has changed the selection
                                                        // so make the call to update the link                                                         
            const createLink = conxtdOut.post(`/Brivo/associateSite/${selectedConxtdSiteId}`, {
              brivo_site_id: selectedBrivoSite.id,
              brivo_site_name: selectedBrivoSite.name,
            });

            createLink.then(() => {
              this.setState({ saving: false });

              this.props.dispatch(
                getBrivoLinkedSitesStart({
                  enterprise_id: enterprise.id,
                })
              );
        
              this.props.onHide();
              
            }).catch((error) => {
              this.setState({ saving: false });

              console.log("Error details", error);
              Alert({
                text: "Issue creating link. Please try again later.",
                icon: "warning",
                showConfirmButton: true,
              });
            });
          } else { // the selected site is the same as the one already linked, so just close the modal
            this.setState({ saving: false });
            this.props.onHide();
          }
        }}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Select Site
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              "height": "470px"
            }}
          >
            <Form.Label>Brivo Site</Form.Label>
            <div className="mb-3">
              {`${selectedBrivoSite.name}${selectedBrivoSite.address && " - " + selectedBrivoSite.address}`}
            </div>
            <Form.Label>CONXTD Site</Form.Label>
            <Select
              options={conxtdSiteOptions}
              value={selectedConxtdSiteOption}
              name="selected_conxtd_site"
              classNamePrefix="selected-conxtd-site-select"
              maxMenuHeight={290}
              // isClearable={true}
              onChange={(e) => {
                this.setState({
                  selectedConxtdSiteOption: e ? _.find(conxtdSiteOptions, ['value', e.value]) : null,
                });
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <div className="container">
              <div className="d-flex justify-content-between">
                {
                  conxtdSite.id ? <Button
                    className="p-2"
                    variant="danger"
                    disabled={saving}
                    onClick={() => {
                      this.setState({ saving: true });

                      const removeLink = conxtdOut.post(`/Brivo/deleteAssociate/${conxtdSite.sudo_site_associate_id}`);
              
                      removeLink.then(() => {
                        this.setState({
                          saving: false,
                          selectedConxtdSiteOption: null,
                        });
          
                        this.props.dispatch(
                          getBrivoLinkedSitesStart({
                            enterprise_id: enterprise.id,
                          })
                        );
                  
                        this.props.onHide();
                        
                      }).catch((error) => {
                        this.setState({ saving: false });
          
                        console.log("Error details", error);
                        Alert({
                          text: "Issue removing link. Please try again later.",
                          icon: "warning",
                          showConfirmButton: true,
                        });
                      });
                    }}
                  >
                    Remove Link
                  </Button>
                  : <div></div>
                }
                <div className="d-flex justify-content-end">
                  <Button
                    className="mr-2 p-2"
                    variant="primary"
                    onClick={() => {
                      this.setState({
                        selectedConxtdSiteOption: null,
                      });
                      this.props.onHide();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="p-2"
                    variant="success"
                    type="submit"
                    disabled={saving || !selectedConxtdSiteOption}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }
}


const mapStateToProps = (state, props) => {
  return {
    conxtdSites: getSites(state),
    enterprise: getEnterpriseFromRoute(state, props),
  };
};

export default connect(mapStateToProps)(BrivoLinkSiteModal);