const initialState = {
  navCollapsed: null,
  darkMode: false,
  router: {
    params: {
      reportPath: ["default", "default"]
    }
  },
  search: {
    open: false,
    value: ""
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "TOGGLE_NAV_COLLAPSE":
      localStorage.setItem("navCollapsed", !state.navCollapsed);
      return {
        ...state,
        navCollapsed: !state.navCollapsed
      };
    case "RESTORE_NAV_COLLAPSE":
      let navCollapsed = action.navCollapsed === "true";
      // Default to collapsing on mobile
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        navCollapsed = false;
      }
      return {
        ...state,
        navCollapsed: navCollapsed
      };
    case "APP_STARTUP":
      const darkMode = localStorage.getItem("darkMode") === "true";
      const production =
        window.location.hostname === "pro.conxtd.com" ||
        window.location.hostname === "app.conxtd.com";

      return {
        ...state,
        darkMode,
        production
      };
    case "TOGGLE_DARK_MODE":
      localStorage.setItem("darkMode", !state.darkMode);
      return {
        ...state,
        darkMode: !state.darkMode
      };
    case "ROUTER_NAVIGATE":
      // Only allow scroll reset on reports
      if (action.location.pathname.includes("reports")) {
        const pageScrollableElement = document.getElementsByClassName(
          "page-content-wrapper"
        )[0];
        pageScrollableElement &&
          pageScrollableElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
      }

      const reportPath = [
        action.params.reportParent || "default",
        action.params.reportType || "default"
      ];
      // If there's a parent report type but no sub type,
      //  set sub type to "index" instead of default
      //  so it acts like a folder
      if (reportPath[0] !== "default" && reportPath[1] === "default") {
        reportPath[1] = "index";
      }

      const { location } = action;
      // const reportPath = ["scheduled", reportType];
      // const reportPath = ["default", "default"];

      return {
        ...state,
        router: {
          params: {
            ...action.params,
            reportPath
          },
          location
        }
      };
    case "GLOBAL_SEARCH_TOGGLE":
      // Allow toggle state to explicitly defined
      const open =
        typeof action.open !== "undefined" ? action.open : !state.search.open;

      return {
        ...state,
        search: {
          ...state.search,
          open
        }
      };
    case "GLOBAL_SEARCH_CHANGE":
      return {
        ...state,
        search: {
          ...state.search,
          value: action.value
        }
      };
    default:
      return state;
  }
};
