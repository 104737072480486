/**
 * User management role IDs. These are consistent across
 * mars staging and live
 */
export default {
  ADMIN: 2,
  MANAGER: 3,
  MEMBER: 4,
  VIEWER: 5
};
