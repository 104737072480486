import React, { Component } from "react";
import { Button, Card, DropdownButton, Dropdown } from "react-bootstrap";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { contains } from "services/filters/";

import ReactTable from "react-table";
import checkboxHOC from "react-table/lib/hoc/selectTable";
import ViewEditSite from "./ViewEditSite";

import "react-table/react-table.css";
import "./ViewGroupTable.scss";

import Icon from "components/Icons/Icon";
import Loading from "components/Loading";
import Alert from "components/Common/Alert";
import { Tooltip } from "components/Common/Tooltip/";

import _ from "lodash";

// selectors
import { getCurrentSite, getBundleId } from "services/redux/selectors/sites";
import { getRouterParams } from "services/redux/selectors/app/";
import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";
import { withT } from "services/translation/";

import SidePane from "components/Common/SidePane/SidePane";
import { Link } from "react-router-dom";
import { loadEventMetaBackgroundStart } from "services/redux/actions/";
import {
  getSiteDetailStart,
  loadEmSitesListStart,
  getGroupStructureStart,
  getCountForMainPageStart,
  loadPinMapsStart,
} from "services/redux/actions/enterpriseManager";
import {
  getGroupFromRoute,
  getGroupPathWithIdById,
  getGroupsWithAccess,
} from "services/redux/selectors/groups";
import { loadEventPairsStart } from "services/redux/actions/index";
import { getSelectedGroupId } from "services/redux/selectors/users";
import GroupPath from "components/Enterprise/Overview/GroupPath";
import MoveSite from "./MoveSite";
import AddSite from "./AddSite";
import Export from "components/Common/EnterpriseManager/Export";
import Import from "components/Common/EnterpriseManager/Import";

import conxtdOut from "apis/conxtdOut";
/**
 * Enterprise Management View Group Page
 *
 * Allows user to:
 * - View sites within a group
 * - Add sites from root group to child group
 * - Move sites from one group to another
 * - Add/Copy sites from one group to another
 * - Remove sites from group
 * - Edit site name, address, custom site ref, custom fields
 **/
const CheckboxTable = checkboxHOC(ReactTable);

class ViewGroupTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      showSidePane: false,
      name: null,
      company: null,
      custom_ref: null,
      site_ref: null,
      transmitter_ref: null,
      address1: null,
      address2: null,
      town: null,
      postcode: null,
      country_code: null,
      group_id: null,
      site_fields: null,
      selection: [],
      existing_groups_full: [],
      existing_groups: [],
      oldGroupId: null,
      selectAll: false,
      setShow: false,
      setShowAddSite: false,
      showExport: false,
      showImport: false,
      showKeyholdersExport: false,
      showKeyholdersImport: false,
    };
  }

  componentDidMount() {
    const { router, enterprise, siteCount } = this.props;
    const group_id = router.params.group_id;
    this.props.dispatch(
      loadEmSitesListStart({
        enterprise_group_id: group_id,
      })
    );
    if (!siteCount || siteCount.length === 0) {
      this.props.dispatch(
        getCountForMainPageStart({
          enterpriseId: enterprise.id
        })
      );
    };
    this.props.dispatch(
      loadEventMetaBackgroundStart({})
    );
      this.props.dispatch(
        loadEventPairsStart()
      );
  }

  componentWillUnmount() {
    document.body.classList.remove("sidepane-active");
  }

  toggleSelection = (key, row, info) => {
    let selection = [...this.state.selection];
    let existing_groups_full = [...this.state.existing_groups_full];

    let groups = info.enterprise_groups;
    let groupsIds = groups && groups.map((groups) => groups.id);

    const keyIndex = selection.indexOf(key);

    if (keyIndex >= 0) {
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1),
      ];
    } else {
      selection.push(key);
    }
    this.setState({ selection });

    if (keyIndex >= 0) { 
      existing_groups_full = [
        ...existing_groups_full.slice(0, keyIndex),
        ...existing_groups_full.slice(keyIndex + 1),
      ];
    } else {
      existing_groups_full.push(groupsIds);
    }
    this.setState({ existing_groups_full: existing_groups_full });

    // the existing_groups array is passed on the api request when the user 
    // either adds the selected sites to a group or moves the selected sites to a group.
    // the back end only uses this array to do some clearing of the cache -
    // it only needs to know the list of discrete groups that any of the selected sites are in,
    // and it does not need to know which sites are in which groups,
    // hence, we can flatten the full list of groups associated with each site, and then exclude duplicates.
    const flattenGroups = existing_groups_full.flat();
    const existing_groups = [...new Set(flattenGroups)]; //creating as a new Set makes sure duplicate values don't go into the array 
    this.setState({ existing_groups: existing_groups }); 
  };

  toggleAll = () => {
    const selectAll = this.state.selectAll ? false : true;
    const selection = [];
    const existing_groups = [];

    if (selectAll) {
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      currentRecords.forEach((item) => {
        selection.push(item._original.id);
        existing_groups.push(
          item._original.enterprise_groups.map((groups) => groups.id)
        );
      });
    }

    const flattenGroups = existing_groups.flat();

    this.setState({
      selectAll,
      selection,
      existing_groups: [...new Set(flattenGroups)],
    });
  };

  isSelected = (key) => {
    return this.state.selection.includes(key);
  };

  deselect = () => {
    this.setState({ selection: [] })
    this.setState({ selectAll: false })
  };

  render() {
    const {
      enterpriseManager,
      router,
      getSiteDetailStart,
      selectedGroupId,
      groupsWithAccess,
      group,
      siteCount,
      darkMode,
    } = this.props;
    const enterprise_id = router.params.enterprise_id;
    const group_id = router.params.group_id;
    const root_group_id = this.props.location.state ? this.props.location.state.root_group_id : null;
    // const mediaType = this.props.browser.mediaType;
    const { toggleSelection, toggleAll, isSelected } = this;
    const { selectAll } = this.state;

    const filter = siteCount.filter(
      (data) => data.enterprise_group_id === parseInt(group_id)
    );
    const result = filter && (filter.length > 0) && filter[0].site_count.live_sites;

    let addModalClose = () => {
      document.body.classList.remove("sidepane-active");
    };
    let loadingMsg = null;

    const { site, bundle_id, onSelectBundle, t } = this.props;

    if (this.props.loadingViewGroup) {
      loadingMsg = <Loading center />;
    } else {
      loadingMsg = "No data available...";
    }

    /** make slider responsive **/
    // let width = null;
    const title = this.state.name || "";

    // if (mediaType === "extraSmall") {
    //   width = "400px";
    // } else if (
    //   mediaType === "small" ||
    //   mediaType === "medium" ||
    //   mediaType === "large" ||
    //   mediaType === "infinity"
    // ) {
    //   width = "530px";
    // }
    /****/

    const columns = [
      {
        Header: "Site Name",
        accessor: "name",
        //Placeholder: "filter box",
        style: {
          whiteSpace: "unset",
          fontWeight: "bold",
        },
      },
      {
        Header: "Custom Site Ref",
        accessor: "custom_ref",
      },
      {
        id: "address",
        Header: "Address",
        style: { whiteSpace: "unset" },
        accessor: (d) =>
          `${d.address1 === null ? "" : d.address1}, ${
            d.address2 === null ? "" : d.address2
          }, ${d.town === null ? "" : d.town}, ${
            d.postcode === null ? "" : d.postcode
          }, ${d.country_code === null ? "" : d.country_code}`,
      },
      {
        Header: "Groups",
        id: "enterprise_groups",
        style: { whiteSpace: "unset" },

        accessor: (enterpriseManager) => {
          let output = [];
          _.map(enterpriseManager.enterprise_groups, (nm) => {
            output.push(nm.name);
          });
          return output.join(", ");
        },
      },
      {
        Header: "Site Ref",
        accessor: "site_ref",
      },
      {
        Header: "Transmitter Id",
        accessor: "transmitter_ref",
      },

      {
        Header: "Actions",
        width: 100,
        maxWidth: 100,
        Cell: (props) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                flexWrap: "wrap",
              }}
            >
              <Button
                style={{ width: "40%" }}
                onClick={(e) => {
                  document.body.classList.add("sidepane-active");
                  this.setState({
                    showSidePane: true,
                    name: props.original.name,
                    company: props.original.company,
                    custom_ref: props.original.custom_ref,
                    site_ref: props.original.site_ref,
                    transmitter_ref: props.original.transmitter_ref,
                    address1: props.original.address1,
                    address2: props.original.address2,
                    town: props.original.town,
                    postcode: props.original.postcode,
                    country_code: props.original.country_code,
                    group_id: group_id,
                    site_fields: props.original.site_fields,
                  });
                  this.props.dispatch(
                    getSiteDetailStart({
                      enterprise_site_id: props.original.id,
                    })
                  );
                  this.props.dispatch(
                    loadPinMapsStart({
                      sudo_site_id: props.original.sudo_site.id,
                    })
                  );
                }}
              >
                {" "}
                <Icon className="fas fa-edit" style={{ textAlign: "center" }} />
              </Button>

              <div className="sidePane">
                <SidePane
                  // SiteView > SidePane > View1, View2
                  title={title}
                  onHide={addModalClose}
                  componentProps={{
                    site,
                    bundle_id,
                    onSelectBundle,
                    name: this.state.name,
                    company: this.state.company,
                    custom_ref: this.state.custom_ref,
                    site_ref: this.state.site_ref,
                    transmitter_ref: this.state.transmitter_ref,
                    address1: this.state.address1,
                    address2: this.state.address2,
                    town: this.state.town,
                    postcode: this.state.postcode,
                    country_code: this.state.country_code,
                    group_id: this.state.group_id,
                    site_fields: this.state.site_fields,
                    onHide: addModalClose,
                  }}
                  // If bundle_id prop is available,
                  // set to view child page (timeline)
                  currentPage={bundle_id ? 1 : 0}
                  onChangePage={(pageKey) => {
                    // Clicked "back", deselect bundle
                    if (pageKey === 0) {
                      onSelectBundle();
                    }
                  }}
                  Pages={[
                    {
                      Component: ViewEditSite,
                    },
                    {
                      backButtonText: t("back_to_overview"),
                      Component: ViewEditSite,
                    },
                  ]}
                />
              </div>
            </div>
          );
        },
      },
    ];

    let groupPath = null;
    if (groupsWithAccess && groupsWithAccess.groups && selectedGroupId) {
      groupPath = getGroupPathWithIdById(   
        groupsWithAccess.groups,
        parseInt(selectedGroupId)
      );
    }

    const checkboxProps = {
      selectAll,
      isSelected,
      toggleSelection,
      toggleAll,
      selectType: "checkbox",
      getTrProps: (s, r) => {
        const selected = r ? this.isSelected(r.original.id) : false;
        return {
          style: {
            backgroundColor: selected 
                              ? darkMode ? "#204183" : "#d6e8fa" 
                              : "inherit",
          },
        };
      },
    };

    const handleClose = () => {
      this.setState({ setShow: false });
      this.deselect();
    };
    const handleCloseAddSite = () => {
      this.setState({ setShowAddSite: false });
      this.deselect();
    };
    const handleShow = () => {
      if (this.state.selection.length === 0) {
        return Alert({
          text:
            "At least one site needs to be selected to perform this action.",
          icon: "warning",
        });
      } else {
        this.setState({ setShow: true });

        this.props.dispatch(
          getGroupStructureStart({
            enterprise_id: enterprise_id,
          })
        );
      }
    };

    // Copy site
    const handleShowAddTo = () => {
      if (this.state.selection.length === 0) {
        return Alert({
          text:
            "At least one site needs to be selected to perform this action.",
          icon: "warning",
        });
      } else {
        this.setState({ setShowAddSite: true });

        this.props.dispatch(
          getGroupStructureStart({
            enterprise_id: enterprise_id,
          })
        );
      }
    };

    // remove site
    const handleChangeRemove = async (event) => {
      const checkboxSelection = this.state.selection;
      const existing_groups = this.state.existing_groups;

      if (this.state.selection.length === 0) {
        return Alert({
          text:
            "At least one site needs to be selected to perform this action.",
          icon: "warning",
        });
      } else {
        Alert({
          title: "Confirm removal",
          icon: "warning",
          showCancelButton: true,
          // toast: true,
          dangerMode: true,
          confirmButtonText: `Remove`,
        }).then(async (result) => {
          if (result.value === true) {
            try {
              await conxtdOut.post(
                `/EnterpriseStructure/deleteSitesFromGroup/`,
                {
                  group_id: group_id,
                  selected_sites: checkboxSelection,
                  existing_groups: existing_groups,
                }
              );
              this.props.dispatch(
                loadEmSitesListStart({
                  enterprise_group_id: group_id,
                })
              );
              Alert({
                text: "Selected sites have been removed..",
                icon: "success",
                timerProgressBar: true,
                timer: 1500,
              });
              this.deselect();
            } catch (error) {
              Alert({
                text: error,
                icon: "error",
              });
            }
          } else {
            // do nothing
          }
        });
      }
    };
    // check if group has children
    let checkGroup = null;

    if (this.props.group === null) {
      checkGroup = null;
    } else {
      checkGroup = this.props.group;
    }

    let onExportClick = () =>
      this.setState({
        showExport: true,
      });

    let onExportReset = () =>
      this.setState({
        showExport: false,
      });

    let onImportClick = () =>
      this.setState({
        showImport: true,
      });

    let onKeyholdersExportClick = () => {
      this.setState({
        showKeyholdersExport: true,
      });
    };

    let onKeyholdersExportReset = () => {
      this.setState({
        showKeyholdersExport: false,
      });
    };

    let onKeyholdersImportClick = () =>
      this.setState({
        showKeyholdersImport: true,
      });

    const handleCloseImport = () => {
      this.setState({ showImport: false });
    };

    const handleCloseKeyholdersImport = () => {
      this.setState({ showKeyholdersImport: false });
    };

    // hide few action buttons when on parent group
    const noChildGroups = checkGroup !== null && checkGroup.children.length === 0;
    return (
      <>
        {/* <Button
          className="float-right"
          variant="primary"
          onClick={onExportClick}
        >
          <div>
            {<Icon className="fas fa-file-export" title="Export" />}
            <span style={{ paddingLeft: "5px" }}>Export</span>
          </div>
        </Button>
        {this.state.showExport ? (
          <Export
            groupId={group_id}
            site_count={result}
            enterpriseName={this.props.enterprise.name}
          />
        ) : null} */}
        <div style={{ display: "flex", marginRight: "10px" }}>
          <Link
            className="button-menu-mobile open-left waves-effect pl-4"
            style={{ backgroundColor: "transparent" }}
            to={`/enterprise/${enterprise_id}/${group_id}/enterprise-manager?tab=groups`}
          >
            <Icon
              style={{ color: "darkslategray" }}
              className="fas fa-chevron-left font-20"
            />
          </Link>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card.Title className="text-uppercase">
              {group ? group.name : this.props.enterprise.name}
            </Card.Title>
            <Card.Subtitle className="mb-3 mt-2 font-12">
              <GroupPath
                delimiter=" > "
                path={groupPath}
                className="text-muted"
                asLinks={false}
              />
            </Card.Subtitle>
          </div>
        </div>

        {noChildGroups ? (
          <div className="d-flex justify-content-end dropdown-container">

            {/* Sites dropdown */}
            <DropdownButton 
              variant="primary mr-3 mb-2"
              title={<span><Icon className="fas fa-building mr-2" title="Sites" />Sites</span>}
            >
              {/* <Dropdown.Item as="button">
                <Link
                  to={{
                    pathname: `/enterprise/${enterprise_id}/create-sites`,
                  }}
                >
                  <span><Icon className="fas fa-plus mr-2" title="Add" />Add</span>
                </Link>
              </Dropdown.Item> */}
              <Dropdown.Item onClick={onExportClick}>
                <span><Icon className="fas fa-file-export mr-2" title="Export" />Export</span>
              </Dropdown.Item>
              <Dropdown.Item onClick={onImportClick}>
                <span><Icon className="fas fa-file-import mr-2" title="Import" />Import</span>
              </Dropdown.Item>
            </DropdownButton>
            {this.state.showExport ? (
              <Export
                groupId={group_id}
                site_count={result}
                enterpriseName={this.props.enterprise.name}
                reset={onExportReset}
              />
            ) : null}
            <Import
              show={this.state.showImport}
              enterpriseId={enterprise_id}
              groupId={group_id}
              onHide={handleCloseImport}
            />

            {/* Keyholders dropdown */}
            <DropdownButton 
              variant="primary mr-3 mb-2"
              title={<span><Icon className="fas fa-address-card fa-lg mr-2" title="Contacts" />Contacts</span>}
            >
              <Dropdown.Item onClick={onKeyholdersExportClick}>
                <span><Icon className="fas fa-file-export mr-2" title="Export" />Export</span>
              </Dropdown.Item>
              <Dropdown.Item onClick={onKeyholdersImportClick}>
                <span><Icon className="fas fa-file-import mr-2" title="Import" />Import</span>
              </Dropdown.Item>
            </DropdownButton>
            {this.state.showKeyholdersExport ? (
              <Export
                path="/EnterpriseKeyholders/export"
                enterpriseId={enterprise_id}
                groupId={group_id}
                enterpriseName={this.props.enterprise.name}
                exportName="Contact Data"
                reset={onKeyholdersExportReset}
              />
            ) : null}
            <Import
              path="/EnterpriseKeyholders/import"
              show={this.state.showKeyholdersImport}
              enterpriseId={enterprise_id}
              groupId={group_id}
              onHide={handleCloseKeyholdersImport}
              importName="Contact"
              importDescription="Contact details"
            />

            {/* Add sites from root button */}
            <Link
              to={{
                pathname: `/enterprise/${enterprise_id}/${group_id}/add-sites-from-root`,
                state: {
                  root_group_id: root_group_id,
                },
              }}
            >
              <Button
                className="float-right"
                variant="primary"
                onClick={() => {
                  this.props.dispatch(
                    loadEmSitesListStart({
                      enterprise_group_id: root_group_id,
                    })
                  );
                }}
              >
                <Icon className="fa fa-plus" /> Add Sites From Root
              </Button>
            </Link>
          </div>
        ) : null}
        

        {noChildGroups ? (
          <div className="d-flex justify-content-between">
            <Card.Subtitle className="mb-2 mt-4 pb-2 text-muted">
              Move, add or remove sites by selecting one, multiple or all sites.
            </Card.Subtitle>
            {/* <Link
              to={{
                pathname: `/enterprise/${enterprise_id}/${group_id}/add-sites-from-root`,
                state: {
                  root_group_id: root_group_id,
                },
              }}
            >
              <Button
                className="float-right"
                variant="primary"
                onClick={() => {
                  this.props.dispatch(
                    loadEmSitesListStart({
                      enterprise_group_id: root_group_id,
                    })
                  );
                }}
              >
                <Icon className="fa fa-plus" /> Add Sites From Root
              </Button>
            </Link> */}
          </div>
        ) : null}

        <div className="site-view" style={{ marginBottom: `${this.state.selection.length > 0 ? "20px" : ""}` }}>
          {noChildGroups ? (
            <CheckboxTable
              ref={(r) => (this.checkboxTable = r)}
              columns={columns}
              data={enterpriseManager}
              keyField="id"
              filterable
              defaultFilterMethod={(filter, row) => {
                return contains({
                  filter: filter.value,
                  value: String(row[filter.id]),
                  ignoreCase: true,
                });
              }}
              {...checkboxProps}
              defaultPageSize={10}
              noDataText={loadingMsg}
              defaultSorted={[
                {
                  id: "name",
                  desc: false,
                },
              ]}
            />
          ) : (
            <ReactTable
              columns={columns}
              data={enterpriseManager}
              filterable
              defaultFilterMethod={(filter, row) => {
                return contains({
                  filter: filter.value,
                  value: String(row[filter.id]),
                  ignoreCase: true,
                });
              }}
              {...checkboxProps}
              defaultPageSize={10}
              noDataText={loadingMsg}
              defaultSorted={[
                {
                  id: "name",
                  desc: false,
                },
              ]}
            />
          )}

          {noChildGroups ? (
            <div 
              className={`fixed-bottom actions-row bg-${darkMode ? "primary" : "light"} ${this.state.selection.length > 0 ? "d-flex" : "d-none"}`}
            >
              <div id="total-sites">
                <span id="total-sites-span">{this.state.selection.length}</span>
                <span 
                  className="total-text"
                >
                  {this.state.selection.length === 1 ? "site" : "sites"}
                </span>
              </div>
              <div className="action-buttons">
                {/** Move **/}
                <Button
                  variant={`${darkMode ? "primary" : "light"}`}
                  type="submit"
                  className="action-button"
                  id="move-button"
                  onClick={handleShow}
                >
                  <Icon className="fas fa-file-import icons" />
                  <span className="action-text">Move</span>
                </Button>
                <MoveSite
                  show={this.state.setShow}
                  onHide={handleClose}
                  treeData={this.props.groupStructure}
                  checkboxSelection={this.state.selection}
                  existing_groups={this.state.existing_groups}
                  groupId={group_id}
                />
                {/** Add to **/}
                <Button
                  variant={`${darkMode ? "primary" : "light"}`}
                  type="submit"
                  className="action-button"
                  id="add-button"
                  onClick={handleShowAddTo}
                >
                  <Icon className="fas fa-copy icons" />
                  <span className="action-text">Add to</span>
                </Button>
                <AddSite
                  show={this.state.setShowAddSite}
                  onHide={handleCloseAddSite}
                  treeData={this.props.groupStructure}
                  checkboxSelection={this.state.selection}
                  existing_groups={this.state.existing_groups}
                  groupId={group_id}
                />
                {/** Delete **/}
                <Button
                  variant={`${darkMode ? "primary" : "light"}`}
                  type="submit"
                  className="action-button"
                  id="delete-button"
                  onClick={handleChangeRemove}
                >
                  <Icon className="fas fa-trash-alt icons" />
                  <span className="action-text">Remove</span>
                </Button>
              </div>
              {/* Deselect All */}
              <div className="deselect-container">
                <Tooltip
                  placement="top"
                  description="Deselect all"
                >
                  <Button
                    className="deselect-all"
                    variant={`${darkMode ? "secondary" : "light"}`}
                    onClick={this.deselect}
                  >
                    <Icon
                      className="fas fa-times"
                    />
                  </Button>
                </Tooltip>
              </div>
            </div>
          ) : null}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    enterprise: getEnterpriseFromRoute(state, props),
    sites: state.sites.all.data,
    group: getGroupFromRoute(state, props),
    groupId: state.groups.data,
    enterpriseManager: state.enterpriseManager.items.enterprise_sites,
    selectedGroupId: getSelectedGroupId(state, props),
    groupsWithAccess: getGroupsWithAccess(state, props),
    loadingViewGroup: state.enterpriseManager.loadingViewGroup,
    getSiteDetailStart,
    loadPinMapsStart,
    siteDetail: state.enterpriseManager.siteDetail.enterprise_site,
    groupStructure: state.enterpriseManager.groupStructure,
    browser: state.browser,
    site: getCurrentSite(state, props),
    router: {
      params: getRouterParams(state, props),
    },
    bundle_id: getBundleId(state, props),
    siteCount: state.enterpriseManager.counts,
    darkMode: state.app.darkMode,
  };
};

ViewGroupTable = connect(mapStateToProps)(
  withT(ViewGroupTable, "site_overview.side_pane")
);
export { ViewGroupTable };
export default withRouter(ViewGroupTable);
