import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Col, Row, Button } from "react-bootstrap";

import Loading from "components/Loading";
import ErrorBadge from "components/Layout/ErrorBadge";

import Alert from "components/Common/Alert";

import { getRouterParams } from "services/redux/selectors/app/";

import { withT } from "services/translation/";

import { SelectResource, SelectChannel, SelectBundle } from "./";
import {
  requestNotificationsAddRuleStart,
  requestNotificationsAddRuleNotified,
} from "services/redux/actions/";

import { devMode } from "services/redux/selectors/";

import conxtdOut from "apis/conxtdOut";

function AddRule({
  onChangeView,
  addRule,
  addingRule,
  addRuleSuccess,
  userNotified,
  addRuleError,
  defaultResource = null,
  router,
  t,
  site_id_store,
}) {
  const [loading, setLoading] = useState(null);
  const [resource, selectResource] = useState(defaultResource);
  const [channel, selectChannel] = useState(null);
  const [bundle, selectBundle] = useState({
    bundle_id: 2,
    class_id: -1,
    colour_id: -1,
  });

  const canSave = !!(!addingRule && resource && channel && bundle);

  const showAddError = addRuleError.message || addRuleError.status;

  if (addRuleSuccess) {
    // Reset success status
    userNotified();
    Alert({
      text: t("add_rule_success"),
      icon: "success",
      onAfterClose: () => {
        // Change view back to notification list
        onChangeView();
      },
    });
  }

  return (
    <>
      <Row className="mt-3">
        <Col md="12">
          <div className="header-title">{t("title")}</div>
        </Col>
      </Row>

      <SelectChannel
        channel={channel}
        selectChannel={(selectedChannel) => {
          // Reset resource when channel changes
          selectResource(defaultResource);

          selectChannel(selectedChannel);
        }}
      />

      {channel && (
        <SelectResource
          channel={channel}
          resource={resource}
          selectResource={selectResource}
        />
      )}

      {channel && resource && (
        <SelectBundle bundle={bundle} selectBundle={selectBundle} />
      )}

      {showAddError && (
        <ErrorBadge
          message={t(
            `errors.add_rule${devMode() ? "_dev" : ""}`,
            addRuleError.message
          )}
        />
      )}

      <Button
        onClick={async () => {
          const { enterprise_id, site_id } = router.params;

          try {
            // On email channel, a string indicates a new email should
            // be created
            let resourceId = resource;

            if (channel === "email" && typeof resource === "string") {
              setLoading(true);
              await conxtdOut.post("/notifications/email/add", {
                email: resource,
              });

              const response = await conxtdOut.post(
                "/notifications/channels/loadResourcesForChannel",
                {
                  channel,
                }
              );

              let data = JSON.stringify(response.data.resources);
              data = JSON.parse(data);

              let findEmail = data.find(({ email }) => email === resource);
              if (findEmail) {
                let id = findEmail.id;
                resourceId = id;
                console.log("Updates resource id is:", resourceId);
              } else {
                // nothing found
                console.log("Nothing found");
              }

              /*
              const response3 = response2.data.resources.id.find(
                ({ element }) => element > 1
              );
              */

              /*
              resourceId = response.data.resources.find(
                ({ email }) => email === resource
              );
              console.log("respone here", resourceId);

              if (!resourceId) throw new Error("Couldn't add email address"); */
            }

            const params = {
              enterprise_id: parseInt(enterprise_id),
              selectedEnterpriseSites:
                typeof site_id === "undefined"
                  ? [parseInt(site_id_store)]
                  : [parseInt(site_id)],

              // selectedGroups: [parseInt(group_id)],

              channel,
              channelResource: parseInt(resourceId),

              ...bundle,
            };

            addRule({ params });
            setLoading(false);
          } catch (e) {
            // s;
            console.error(e);
          }
        }}
        className="mt-3"
        disabled={!canSave || loading}
        variant="success"
        block
        data-testid="save-rule-button"
      >
        {loading || addingRule ? <Loading center /> : t("save")}
      </Button>
      <Button onClick={onChangeView} variant="danger" block>
        {t("cancel")}
      </Button>
    </>
  );
}
AddRule.propTypes = {
  /**
   * @param onChangeView back to overview callback
   */
  onChangeView: PropTypes.func.isRequired,

  /**
   * @param addingRule loading status for adding a rule
   */
  addingRule: PropTypes.any,

  /**
   * @param userNotified action to indicate
   * that the user has been notified
   * and reset success state
   */
  userNotified: PropTypes.func.isRequired,

  /**
   * @param addRuleError error status for adding a rule
   */
  addRuleError: PropTypes.object.isRequired,

  /**
   * @param addRuleError success status for adding a rule
   */
  addRuleSuccess: PropTypes.any,

  /**
   * @param router router object for site id, enterprise id
   * and group id
   */
  router: PropTypes.object.isRequired,

  /**
   * @param defaultResource (optional) the initial state to use for resource
   */
  defaultResource: PropTypes.any,

  /**
   * @param t translation function
   */
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  addRule: requestNotificationsAddRuleStart,
  userNotified: requestNotificationsAddRuleNotified,
};
const mapStateToProps = (state, props) => {
  return {
    router: { params: getRouterParams(state, props) },
    addingRule: state.notifications.rules.actions.add.loading === true,
    addRuleError: state.notifications.rules.actions.add.error,
    addRuleSuccess: state.notifications.rules.actions.add.success,
    site_id_store: state.sites.current.id,
  };
};

export default withT(
  connect(mapStateToProps, mapDispatchToProps)(AddRule),
  "site_overview.side_pane.manage_notifications.add_rule"
);
